<pb-loading-bar *ngIf="loading"></pb-loading-bar>
<router-outlet></router-outlet>
<!-- <ng-template #template>
  <div class="modal-body" style="padding:5px;">
    <div class="alert alert-danger NoMargin" role="alert">
      <p class="NoMargin">
        <i class="material-icons pull-left"> warning </i>
        &nbsp; You loss your internet connection. Please check internet connection.
      </p>
    </div>
  </div>
</ng-template> -->
