import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MarketBet } from '@clientApp-core/models/market/market-bet.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private _loadingStatus = new Subject<any>();
  private _setSelectedTab = new Subject<any>();
  private _isMatchesFilter = new Subject<any>();
  private _oneClickStakeStatus = new Subject<any>();
  private _oneClickBetStatus = new Subject<boolean>();
  inPlay = new Subject<any>();
  centralizationIds: string;
  inPlayStatus = false;
  public sessionPostion: any = [];
  configData: any;
  marketClientBets: MarketBet[] = [];
  allMarkets = []; 
  selectedTab = 'InPlay';
  gameraceStatus = false;
  selectedRecingGame = '';
  isMarketLiabilityClick: boolean;
  chipList: any = [];
  isShowLiveTvCenter = false;
  ipAddress: string = '';
  constructor(private cookieService: CookieService) { }
  setCookieValue(name: string, value: string,) {
    const domain = window.location.hostname;
    this.cookieService.set(name, value, 365,'/',domain ? domain : '',false,'Lax');
  }
  getCookieValue(name: string) {
    return this.cookieService.get(name);
  }
  getLoadingStatus(): Observable<boolean> {
    return this._loadingStatus.asObservable();
  }
  setLoadingStatus(status: boolean) {
    this._loadingStatus.next(status);
  }
  getOneClickStakeStatus(): Observable<string> {
    return this._oneClickStakeStatus.asObservable();
  }
  setOneClickStakeStatus(stake: string) {
    this._oneClickStakeStatus.next(stake);
  }
  getOneClickBetStatus(): Observable<boolean> {
    return this._oneClickBetStatus.asObservable();
  }
  setOneClickBetStatus(isChecked: boolean) {
    this._oneClickBetStatus.next(isChecked);
  }
  getInPlayStatus(): Observable<boolean> {
    return this.inPlay.asObservable();
  }
  setInPlayStatus(status: boolean) {
    this.inPlay.next(status);
  }
  getCategorieWiseMatches(): Observable<any> {
    return this._isMatchesFilter.asObservable();
  }
  setLoadingCategoriWiseMatches(showMatch: any) {
    this._isMatchesFilter.next(showMatch);
  }
  getSelectedTab(): Observable<any> {
    return this._setSelectedTab.asObservable();
  }
  setSelectedTab(selectedTab: any) {
    this._setSelectedTab.next(selectedTab);
  }
}


