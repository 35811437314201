import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';
import { AudioPlayerComponent } from '@clientApp-shared/components/audio-player/audio-player.component';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';
import { AuthFacadeService, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { AudioType } from '@clientApp-core/enums/audio.types';
import { catchError, take } from 'rxjs/operators';
import { RulesComponent } from '@clientApp-shared/components/rules/rules.component';
import { NavigationEnd, Router } from '@angular/router';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { select, Store } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import * as SelectedMarket from '@clientApp-store/selected-market/actions/selected-market.actions';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { ConnectionState } from '@clientApp-core/enums/connectionState-type';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import * as fromSelectedMarket from '@clientApp-store/selected-market/selectors/selected-market.selectors';
import { fancyRankOrder, GetSortOrder } from '@clientApp-core/utilities/app-configuration';
import { Market } from '@clientApp-core/models/market/market.model';
declare var $: any;
declare var _: any;

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  loading: boolean;
  interval;
  allMarkets: any = [];
  private previousUrl: string;
  private currentUrl: string;
  @ViewChild('player', { static: true }) audioPlayerRef: AudioPlayerComponent;
  constructor(private commonService: CommonService,
    private ngZone: NgZone,
    private deviceInfoService: DeviceInfoService,
    private marketRateService: MarketRateFacadeService,
    private authService: AuthFacadeService,
    private storeService: StoreService,
    private router: Router,
    private marketFacadeService: MarketFacadeService,
    private sessionService: SessionService,
    private nodeCentralService: NodeCentralService,
    private store: Store<ParkBetState>) {
    this.commonService.getLoadingStatus().subscribe(status => {
      this.loading = status;
    });
    this.marketRateService.getAudioType$().subscribe((type: AudioType) => {
      this.audioPlayerRef.playAudio(type);
    });
    const self = this;
    this.currentUrl = this.router.url;
    this.previousUrl = null;
    this.router.events.subscribe(val => {
        if (
          val instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = val.urlAfterRedirects;
            // console.log("prev: ", this.previousUrl)
            // console.log("curr: ", this.currentUrl)
          if (this.previousUrl !== this.currentUrl) {
            // console.log("checkCurrentStatus");
            self.checkCurrentStatus();
            if (this.previousUrl !== '/home' && this.currentUrl !== '/market') {
              self.removeCentralGroup();
            }
          }          
        }
    });
  }

  ngOnInit() {
    if(window.location.hash != '#/market') { this.authService.getConfig(); }
    this.authService.getCasinoConfig();
    this.getConfig();
    this.checkSession();
  }
  getConfig() {
    this.authService.getConfig$()
      .pipe(
        untilDestroyed(this),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.commonService.configData = response;
          this.sessionService.startHubConnections();
          this.nodeCentralService.StartSocketConnection();
        }
      }, err => console.log('getConfig', err));
  }
  checkSession() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (user != null) {
      this.getExpTime(user.expireOn);
    }
  }
  onSwipe(evt) {
    if (this.deviceInfoService.isMobile()) {
      const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
      const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
      if (x === 'left') {
        $('.nav-tabs a.active').next('a').tab('show');
      } else if (x === 'right') {
        $('.nav-tabs a.active').prev('a').tab('show');
      }
      // console.log(`${x} ${y}`);
    }
  }
  getExpTime(strdate) {
    const now = new Date(); //  GMT+0530 (India Standard Time)
    const isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    const today: any = new Date(isoDate);
    const expDate: any = new Date(strdate);
    const diffMs = (expDate - today); // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    let callAgain = false;
    if (diffDays <= 0) {
      if (diffHrs <= 0) {
        if (diffMins <= 4) {
          clearTimeout(this.interval);
          this.getNewToken();
        } else {
          callAgain = true;
        }
      } else {
        callAgain = true;
      }
    } else {
      callAgain = true;
    }

    if (callAgain) {
      this.ngZone.runOutsideAngular(() => {
        this.interval = window.setTimeout(() => {
          this.getExpTime(strdate);
        }, 60000);
      });
    }
    // console.log('H:', diffHrs, 'M:', diffMins);
  }
  getNewToken() {
    this.authService.IsAuthorized$().pipe(take(1)).subscribe((data: any) => {
      if (data.isAuthorized) {
        if (data.isNewToken) {
          const user = JSON.parse(localStorage.getItem('token'));
          if (user != null) {
            user.access_token = data.token;
            user.expireOn = data.expireOn;
            localStorage.setItem('token', JSON.stringify(user));
            this.checkSession();
          }
        }
      } else {
        // logout
        this.storeService.clearStore();
      }
    });
  }
  checkCurrentStatus() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (this.checkCurrentStateToProcced(this.sessionService.centeralHubState,
      this.sessionService.marketHubState)) {
      console.log('RE Disconnected');
      if (user != null) {
        this.sessionService.startHubConnections();
      }
    }
    // else {
    //     console.log('RE Connected');
    // }
    if (user != null && !this.nodeCentralService.socketState) {
      this.nodeCentralService.StartSocketConnection();
    }
  }
  private checkCurrentStateToProcced(marketHubState: number, centralHubState: number): boolean {
    if (marketHubState === ConnectionState.connected && centralHubState === ConnectionState.connected) {
      return false;
    } else {
      return true;
    }
    // return !(!!marketHubState && !!centralHubState);
  }
  removeCentralGroup() {
    const markets = _.cloneDeep(this.marketFacadeService.marketList);
    this.allMarkets = markets.sort((a, b) => {
      return fancyRankOrder.get(a.appFancyType) - fancyRankOrder.get(b.appFancyType);
    }).sort(GetSortOrder('appDate'));
    const excludeSports = apiEndPointData.data.excludeSports;
    const excludeSport = excludeSports.map(x => x.name);
    const uniqueMarkets = _.uniqBy(_.filter(this.allMarkets, (v) => !_.includes(excludeSport, v.appSport)), 'appMatchID');
    let centralizationIds = this.getSelectedMarket().map(match => match.appCentralizationID).toString();
    if (centralizationIds && centralizationIds !== '' && centralizationIds.length > 0) {
      this.sessionService.removeAllCentralGroup(centralizationIds);
    } else {
      centralizationIds = uniqueMarkets.map(match => match.appCentralizationID).toString();
    }
    if (websiteSettings.data.isSocketRateEnabled) {
      this.nodeCentralService.removeMarket(centralizationIds);
    } else {
      this.sessionService.removeCentralDashboardGroup(centralizationIds);
    }
  }
  private  getSelectedMarket(): Market[] {
    let selectedMarkets: Market[] ;
    this.store
    .pipe(select(fromSelectedMarket.getAllMarkets), take(1), catchError(err => throwError(err)))
    .subscribe(markets => selectedMarkets = markets, err => console.log('getSelectedMarket', err));
    return selectedMarkets;
  }
  ngOnDestroy() {
    window.clearTimeout(this.interval);
  }
}
