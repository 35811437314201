import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { websiteSettings } from '../authentication/authentication-facade.service';

@Injectable({
  providedIn: 'root'
})
export class NodeCentralService {
  isStartLiveScore = websiteSettings.data.isStartLiveScore; // start live score flag
  isStartSocketScore = websiteSettings.data.isStartBetfairLiveScore; // start socket/Betfair score
  // isSocketScoreEnabled = websiteSettings.data.isSocketScoreEnabled; // start scoket/websocket score
  private isSocketRateEnabled = websiteSettings.data.isSocketRateEnabled;
  private isSocketScoreEnabled = websiteSettings.data.isSocketScoreEnabled;
  private isStartSocketIO = websiteSettings.data.isStartSocketIO;
  private url = websiteSettings.data.nodeCentralUrl;
  private webSocketUrl = websiteSettings.data.webSocketUrl;
  private siteName = websiteSettings.data.siteName;
  private loginType = websiteSettings.data.loginType;
  private socket;
  private RateData = new ReplaySubject(20);
  private ScoreData = new Subject();
  marketBfIds = '';
  private ws;
  private _connectionState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
  };
  private _isUserInitiated = false;
  reConnectattemptNumber = 0;
  centralizationIds = '';
  reconnectAttempt = websiteSettings.data.wsreconnectAttempt ? websiteSettings.data.wsreconnectAttempt : 10;
  constructor() {
    // this.startScoketConnection();
  }
  get socketState(): boolean {
      if (this.isSocketScoreEnabled) { // stop web socket
        if (this.ws) {
          return this.ws.readyState === this._connectionState.OPEN ? true : false;
        }
      } else if(this.isStartSocketIO) { // stop sccket io socket
        if (this.socket) {
          return this.socket.connected;
        }
      } else {
        return false;
      }
  }
  StartSocketConnection() {
    this.isSocketRateEnabled = websiteSettings.data.isSocketRateEnabled;
    this.isSocketScoreEnabled = websiteSettings.data.isSocketScoreEnabled;
      if (this.isSocketScoreEnabled) {
        this.startWebSocket();
      } else if(this.isStartSocketIO) {
        this.startSocketIo();
      }
  }
  StopSocketConnection() {
      if (this.isSocketScoreEnabled) {
        this.stopWebSocket();
      } else if(this.isStartSocketIO) {
        this.stopSocketIo();
      }
  }
  startSocketIo() {
    const self = this;
    this.reconnectAttempt = websiteSettings.data.wsreconnectAttempt ? websiteSettings.data.wsreconnectAttempt : 3;
    this.url = websiteSettings.data.webSocketRateUrl;
    this.siteName = websiteSettings.data.siteName;
    this.loginType = websiteSettings.data.loginType;
    const user = JSON.parse(localStorage.getItem('token'));
    const token = '?token=' + this.siteName + '_' + this.loginType + '_' + user.name + user.accessToken;
    this.socket = io(this.url + token, {
      'transports': ['websocket']
    }).connect();
    this.socket.on('connect', () => {
      console.log('socket connected');
      self.addnewMarket(self.centralizationIds);
    });
    this.socket.on('connect_timeout', (timeout) => {
      console.log('socket connect timeout');
      if (!self.socket.connected) {
        window.setTimeout(() => {
          if (!self.socket.connected) { self.socket.connect(); }
        }, 5000);
      }
    });
    this.socket.on('connect_error', (error) => {
      console.log('connect_error', error);
      if (!self.socket.connected) {
        window.setTimeout(() => {
          if (!self.socket.connected) { self.socket.connect(); }
        }, 5000);
      }
    });
    this.socket.on('error', (error) => {
      console.log('socket error' + error);
      if (!self.socket.connected) {
        window.setTimeout(() => {
          if (!self.socket.connected) { self.socket.connect(); }
        }, 5000);
      }
    });
    this.socket.on('disconnect', () => {
      console.log('socket disconnect');
      window.setTimeout(() => {
        if (!self.socket.connected) { self.socket.connect(); }
      }, 5000);
    });
    this.socket.on('reconnect', (attemptNumber) => {
      console.log('socket reconnect', attemptNumber);
      self.addnewMarket(self.centralizationIds);
    });
    this.socket.on('reconnect_attempt', (attemptNumber) => {
      console.log('sreconnect_attempt', attemptNumber);
    });
    this.socket.on('reconnecting', (attemptNumber) => {
      console.log('reconnecting', attemptNumber);
    });
    this.socket.on('reconnect_error', (error) => {
      console.log('reconnect_error', error);
    });
    this.socket.on('reconnect_failed', (error) => {
      console.log('reconnect_failed', error);
    });
    this.socket.on('market_data', data => {
      try {
        if (data && data.messageType === 'score') {
          if (data.data.length > 0) {
            this.ScoreData.next(data);
          } else {
            this.ScoreData.next(null);
          }
        } else {
          this.RateData.next(JSON.parse(data));
        }
      } catch (ex) { console.log(ex); }
    });

  }
  startWebSocket() {
    this.reconnectAttempt = websiteSettings.data.wsreconnectAttempt ? websiteSettings.data.wsreconnectAttempt : 3;
    this.webSocketUrl = websiteSettings.data.webSocketRateUrl;
    this.siteName = websiteSettings.data.siteName;
    this.loginType = websiteSettings.data.loginType;
    const self = this;
    const user = JSON.parse(localStorage.getItem('token'));
    const url = this.webSocketUrl + '?token=' + this.siteName + '_' + this.loginType + '_' + user.clientName + user.access_token;
    this.ws = new WebSocket(url);
    this.ws.onopen = function (e) {
      console.log('WebSocket Connection established');
      self.addnewMarket(self.centralizationIds);
    };
    this.ws.onmessage = function (event) {
      try {
        if (event && event.data) {
          const data = JSON.parse(event.data);
          if (data && data.messageType === 'score') {
            if (data.data.length > 0) {
              self.ScoreData.next(data);
            } else {
              self.ScoreData.next(null);
            }
          } else {
            self.RateData.next(JSON.parse(data.data));
          }
        }
      } catch (ex) { console.log('onmessage', ex); }
    };

    this.ws.onclose = function (event) {
      if (self._isUserInitiated !== undefined && !self._isUserInitiated) {
        if (!navigator.onLine) {
          console.log('You are offline. Please connect to the Internet and try again.');
        } else {
          if (self.ws.readyState === self._connectionState.CLOSED) {
            var reason;
            if (event.code == 1000)
              reason = "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
            else if (event.code == 1001)
              reason = "An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.";
            else if (event.code == 1002)
              reason = "An endpoint is terminating the connection due to a protocol error";
            else if (event.code == 1003)
              reason = "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
            else if (event.code == 1004)
              reason = "Reserved. The specific meaning might be defined in the future.";
            else if (event.code == 1005)
              reason = "No status code was actually present.";
            else if (event.code == 1006)
              reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
            else if (event.code == 1007)
              reason = "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).";
            else if (event.code == 1008)
              reason = "An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.";
            else if (event.code == 1009)
              reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
            else if (event.code == 1010) // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
              reason = "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " + event.reason;
            else if (event.code == 1011)
              reason = "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
            else if (event.code == 1015)
              reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
            else
              reason = "Unknown reason";

            console.log('WeSocket is closed. ', event.code + "-" + reason);
            if (self.reConnectattemptNumber < self.reconnectAttempt) {
              self.reConnectattemptNumber = self.reConnectattemptNumber + 1;
              console.log('WeSocket is closed. Reconnect will be attempted in 5 second.');
              setTimeout(function () {
                self.startWebSocket();
              }, 5000);
            }
          }
        }
      }
    };

    this.ws.onerror = function (error) {
      console.error('WebSocket encountered error: ', error.message, 'Closing socket');
      self.ws.close();
    };
  }
  stopWebSocket() {
    if (this.ws) {
      this.ws.close();
      this._isUserInitiated = true;
      console.log('WeSocket is closed');
    }
  }
  stopSocketIo() {
    if (this.socket) {
      this._isUserInitiated = true;
      this.socket.disconnect();
      console.log('Socket is closed');
    }
  }
  addnewMarket(bfId) {
    const self = this;
      if (this.isSocketScoreEnabled) {
        if (bfId && bfId.length > 0) {
          this.centralizationIds = bfId;
          if (this.ws && this.ws.readyState === this._connectionState.OPEN) {
            // console.log('bfID', bfId);
            const obj = JSON.stringify({ 'action': 'set', 'markets': bfId });
            this.ws.send(obj);
          }
        } else {
          setTimeout(function () {
            self.addnewMarket(bfId);
          }, 5000);
        }
      } else if(this.isStartSocketIO) {
        if (bfId && bfId.length > 0) {
          this.centralizationIds = bfId;
          if (this.socket && this.socket.connected) {
            this.socket.emit('set_market', {
              markets: bfId
            });
          } else {
            setTimeout(function () {
              self.addnewMarket(bfId);
            }, 5000);
          }
        }
      }
  }
  removeMarket(bfId) {
      if (this.isSocketScoreEnabled) {
        if (this.ws.readyState === this._connectionState.OPEN) {
          if (bfId && bfId.length > 0) {
            //  console.log('unset', bfId);
            if (this.ws && this.ws.readyState === this._connectionState.OPEN) {
              const obj = JSON.stringify({ 'action': 'unset', 'markets': bfId });
              this.ws.send(obj);
            }
          }
        }
      } else if(this.isStartSocketIO) {
        if (bfId && bfId.length > 0) {
          if (this.socket && this.socket.connected) {
            this.socket.emit('unset market', {
              markets: bfId
            });
          }
        }
      }
  }
  getRateDataSubject(): Observable<any> { return this.RateData.asObservable(); }
  getScoreDataSubject(): Observable<any> { return this.ScoreData.asObservable(); }
  unSubscribeSocketIO() {
    if (this.isSocketRateEnabled) {
      this.removeMarket(this.centralizationIds);
    }
    // this.removeMarket();
    // console.log('unSubscribe socket..........:)');
    // this.socket.removeAllListeners('set market value');
  }
}
