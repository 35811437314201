import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { LiabilityComponent } from '@clientApp-shared/components/liability/liability.component';
declare var $: any;
import { NewsFacadeService } from '@clientApp-core/services/news/news-facade.service';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as selectedMarket from '@clientApp-store/selected-market/selectors/selected-market.selectors';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { RulesComponent } from '@clientApp-shared/components/rules/rules.component';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { AuthFacadeService, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import * as SelectedMarket from '@clientApp-store/selected-market/actions/selected-market.actions';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import * as M from "materialize-css/dist/js/materialize";
import { OneClickComponent } from '../one-click/one-click.component';
import { FanceType } from '@clientApp-core/enums/market-fancy.type';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
declare var _: any;
@Component({
    selector: 'pb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('liability', { static: true }) liabilityRef: LiabilityComponent;
    @ViewChild('rules', { static: true }) rulesRef: RulesComponent;
    @ViewChild('oneclick', { static: true }) oneclickRef: OneClickComponent;
    account: any;
    user: any;
    news: any;
    selectedmarket: any;
    isShowBalance = true;
    loading = false;
    isLibShow = false;
    logoUrl = apiEndPointData.data.logoUrl;
    isMultiPin = false;
    selectedSport = '';
    markets = [];
    allMarkets: any;
    config: any;
    excludeSport = [];
    excludeSports = [];
    excludeSportsMarket = [];
    isListwithTournament: boolean;
    isCheckedOneClickBet: boolean = false;
    isShowOneClickBet: boolean = websiteSettings.data.isShowOneClickBet;
    selectedStakeValue: string;
    oneClickStakeData = [];
    constructor(private store: Store<ParkBetState>, public betService: BetFacadeService,
        public router: Router, private sessionService: SessionService,
        private marketService: MarketFacadeService,private nodeCentralService: NodeCentralService,
        public storeService: StoreService, private newsService: NewsFacadeService, private commonService: CommonService,
        private authService: AuthFacadeService, private marketFacadeService: MarketFacadeService) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const currentUrl = val.url;
                if (currentUrl.indexOf('/casinoGame') > -1) {
                    this.isShowBalance = false;
                } else {
                    this.isShowBalance = true;
                }
                if (currentUrl.indexOf('/home') === -1 && currentUrl.indexOf('/market') === -1) {
                    localStorage.setItem('is_MultiPin', JSON.stringify({ isMultiPin: false }));
                    this.isMultiPin = false;
                }
                if (currentUrl.indexOf('/market') > -1) {
                    if (localStorage.getItem('selectedSport')) {
                        this.selectedSport = JSON.parse(localStorage.getItem('selectedSport'));
                    } else {
                        this.selectedSport = this.commonService.selectedTab;
                    }
                } else {
                    this.selectedSport = this.commonService.selectedTab;
                }
            }
        });

    }

    ngOnInit() {
        this.getConfig();
        this.isShowOneClickBet = websiteSettings.data.isShowOneClickBet;
        this.excludeSports = apiEndPointData.data.excludeSports;
        this.excludeSport = this.excludeSports.map(x => x.name);
        this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
        this.getMarkets();
        
        this.user = JSON.parse(localStorage.getItem('token'));
        this.betService.getBalance();
        this.betService.checkBalance$().pipe(
            switchMap((resp) => {
                return of(resp);
            }
            )
        ).subscribe(value => {
            this.account = value;
            if (this.account && this.account.liability) {
                this.account.liability = Math.abs(this.account.liability);
            }
        });
        this.getNews();
        const currentState = localStorage.getItem('is_MultiPin');
        this.isMultiPin = (JSON.parse(currentState) != null && JSON.parse(currentState).isMultiPin) ? true : false;
        this.excludeSportsMarket = _.filter(this.allMarkets, (v) => _.includes(this.excludeSport, v.appSport));
        this.commonService.getSelectedTab().pipe(untilDestroyed(this)).subscribe(x => { this.selectedSport = x; localStorage.setItem('selectedSport', JSON.stringify(this.selectedSport)); });
        const oneclickCurrentState = this.commonService.getCookieValue('isCheckedOneClickBet');
        this.isCheckedOneClickBet = (oneclickCurrentState != null && oneclickCurrentState != undefined && oneclickCurrentState != '' && JSON.parse(oneclickCurrentState).isCheckedOneClickBet) ? true : false;
        const oneClickSelectedStake = this.commonService.getCookieValue('oneClickSelectedStake');
        if (oneClickSelectedStake != null && oneClickSelectedStake != undefined && oneClickSelectedStake != '' && JSON.parse(oneClickSelectedStake)) {
            this.selectedStakeValue = JSON.parse(oneClickSelectedStake);
        }
        this.commonService.getOneClickStakeStatus().subscribe(stakevalue => this.selectedStakeValue = stakevalue);
        this.commonService.getOneClickBetStatus().subscribe(isChecked => {
            this.isCheckedOneClickBet = isChecked;
        });
    }
    ngAfterViewInit() {
        let elems = document.querySelectorAll('.dropdown-trigger');
        let instances = M.Dropdown.init(elems, {});
        $('ul.dropdown-content li').on('touchend', function (e) {
            e.stopPropagation();
        });
    }
    getNews() {
        this.newsService.getNews$()
            .pipe(
                untilDestroyed(this),
                take(1),
                catchError(err => throwError(err))
            ).subscribe(response => {
                this.news = response;
            }, err => console.log('getNews', err));
    }
    getMarkets() {
        this.marketService.getMarkets$()
            .pipe(
                untilDestroyed(this),
                catchError(err => throwError(err))
            ).subscribe(response => {
                if (response && response.length > 0) {
                    this.allMarkets = response;
                    this.excludeSportsMarket = _.filter(this.allMarkets, (v) => _.includes(this.excludeSport, v.appSport));
                    this.getMarketList();
                }
            }, err => console.log('left menu getMarkets', err));
    }
    getMarketList() {
        if (this.allMarkets && this.allMarkets.length > 0) {
            let mappedMarket: any = [];
            if (this.isListwithTournament) {
                mappedMarket = this.mapMarketTorunaments(_.orderBy(this.allMarkets, o => new Date(o.appDate), 'asc'));
                mappedMarket = mappedMarket.map((market) => {
                    return { sport: market.sport, appTournament: this.appTournament(market.appTournament) };
                });
            } else {
                mappedMarket = this.mapMarket(_.orderBy(this.allMarkets, o => new Date(o.appDate), 'asc'));
                mappedMarket = mappedMarket.map((market) => {
                    return { sport: market.sport, matches: this.mapMatch(market.matches) };
                });
            }
            const filterdArr = _.filter(mappedMarket, (v) => !_.includes(this.excludeSport, v.sport));

            this.markets = _.sortBy(filterdArr, function (item) {
                return item.sport === 'Cricket' ? 0 : 1;
            });
        }
    }
    getSportwiseInplayCount(sport) {
        let filterdArr = [];
        if (websiteSettings.data.isShowMarketOddsWiseInPlay) {
            filterdArr = this.allMarkets.filter((market) => {
                return market.appFancyType === FanceType.Market && market.appIsInPlay
            });
        } else if (websiteSettings.data.isShowMarketBookManulWiseInPlay) {
            filterdArr = this.allMarkets.filter((market) => {
                return (market.appFancyType === FanceType.Market || market.appFancyType === FanceType.Bookmakers || market.appFancyType === FanceType.ManualOdds) && market.appIsInPlay
            });
        } else if (websiteSettings.data.isShowAnyMarketWiseInPlay) {
            filterdArr = this.allMarkets.filter((market) => {
                return market.appIsInPlay
            });
        } else {
            filterdArr = this.allMarkets.filter((market) => {
                return market.appFancyType !== FanceType.AdvanceSession && market.appIsInPlay
            });
        }
        return _.uniqBy(filterdArr.filter(x => x.appSport === sport), 'appMatchID').length;
    }
    getConfig() {
        this.authService.getConfig$()
            .pipe(
                untilDestroyed(this),
                take(1),
                catchError(err => throwError(err))
            ).subscribe(response => {
                if (response) {
                    this.commonService.configData = response;
                    this.config = response;
                    this.isShowOneClickBet = websiteSettings.data.isShowOneClickBet;
                    this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
                    this.getMarketList();
                }
            }, err => console.log('getConfig', err));
    }
    private mapMarket(markets) {
        return _.chain(markets)
            .groupBy('appSport')
            .toPairs()
            .map(function (currentItem) {
                return _.fromPairs(_.zip(['sport', 'matches'], currentItem));
            })
            .value();
    }
    private mapMatch(markets) {
        return _.chain(markets)
            .groupBy('appMatch')
            .toPairs()
            .map(function (currentItem) {
                return _.fromPairs(_.zip(['name', 'values'], currentItem));
            })
            .value();
    }
    private mapMarketTorunaments(markets) {
        return _.chain(markets)
            .groupBy('appSport')
            .toPairs()
            .map(function (currentItem) {
                return _.fromPairs(_.zip(['sport', 'appTournament'], currentItem));
            })
            .value();
    }
    private appTournament(markets) {
        return _.chain(markets)
            .groupBy('appTournament')
            .toPairs()
            .map(function (currentItem) {
                currentItem = [currentItem[0],
                _.chain(currentItem[1])
                    .groupBy('appMatch')
                    .toPairs()
                    .map(function (currentItem) {
                        return _.fromPairs(_.zip(['name', 'values'], currentItem));
                    }).value()
                ]
                return _.fromPairs(_.zip(['tournamentName', 'matches'], currentItem));
            })
            .value();
    }
    onInPlayClick() {
        this.commonService.selectedTab = 'InPlay';
        this.selectedSport = 'InPlay';
        this.commonService.gameraceStatus = false;
        localStorage.removeItem('selectedSport');
        this.commonService.setLoadingCategoriWiseMatches('InPlay');
        this.router.navigateByUrl('/home');
    }
    onMultiMarketsClick() {
        this.isMultiPin = false;
        let multiSelectedMatch: any;
        multiSelectedMatch = JSON.parse(localStorage.getItem('multiselected_matchIds'));

        if (multiSelectedMatch !== null && multiSelectedMatch.length > 0) {
            const allMarkets = this.marketFacadeService.marketList;
            const uniqueMarkets = _.filter(allMarkets, function (x) {
                return _.includes(multiSelectedMatch, x.appMatchID);
            });
            const selectedMatches = _.chain(uniqueMarkets)
                .groupBy('appMatchID')
                .toPairs()
                .map(function (currentItem) {
                    return _.fromPairs(_.zip(['id', 'matches'], currentItem));
                }).value();


            this.storeService.removeAllStoreData();
            const centralizationIds = selectedMatches.map(x => _.map(x.matches, 'appCentralizationID')).toString();
            if (websiteSettings.data.isSocketRateEnabled) {
                this.nodeCentralService.addnewMarket(centralizationIds);
            } else {
                this.sessionService.joinMultiCentralGroup(centralizationIds);
            }
            selectedMatches.forEach((x: any) => {
                x.matches.forEach((market: any) => {
                    this.store.dispatch(SelectedMarket.UpsertSelectedMarket({ market: market }));
                    this.sessionService.addMarketGroup('', 3, market.appMatchID, market.appBetID, market.appCentralizationID, 0, 0, 0);
                });
            });
            localStorage.setItem('is_MultiPin', JSON.stringify({ isMultiPin: true }));
            this.isMultiPin = true;
            this.selectedSport = 'MultiPin';
            localStorage.setItem('selectedSport', JSON.stringify(this.selectedSport));
            this.router.navigateByUrl('/market');
        }

    }
    onSportsClick(selectedSports: string) {
        this.commonService.selectedTab = selectedSports;
        this.selectedSport = selectedSports;
        this.commonService.gameraceStatus = false;
        this.commonService.setLoadingCategoriWiseMatches(selectedSports);
        localStorage.setItem('selectedSport', JSON.stringify(this.selectedSport));
        this.router.navigateByUrl('/home');
    }
    onLogoClick() {
        this.commonService.selectedTab = 'Home';
        this.selectedSport = 'Home';
        this.commonService.gameraceStatus = false;
        localStorage.setItem('is_MultiPin', JSON.stringify({ isMultiPin: false }));
        this.commonService.setLoadingCategoriWiseMatches('Home');
        this.storeService.removeAllStoreData();
        localStorage.removeItem('selectedSport');
        this.router.navigateByUrl('/home');
    }
    excludeSportsClick(selectedSport) {
        this.selectedSport = selectedSport;
        this.commonService.selectedTab = selectedSport;
        this.commonService.selectedRecingGame = selectedSport;
        this.commonService.gameraceStatus = true;
        localStorage.setItem('selectedSport', JSON.stringify(selectedSport));
        localStorage.setItem('is_MultiPin', JSON.stringify({ isMultiPin: false }));
        this.commonService.setLoadingCategoriWiseMatches(selectedSport);
        this.router.navigateByUrl('/home');
    }
    isActiveExcludeSports(name) {
        return !!this.excludeSportsMarket.find(x => x.appSport === name);
    }
    onLiabilityClick() {
        if (this.account && this.account.liability !== 0) {
            this.liabilityRef.openPopup();
        }
    }
    onLogOut() {
        this.authService.LogOut$();
        this.storeService.clearStore();
    }
    removeAllGroup() {
        this.store.pipe(select(selectedMarket.getAllMarkets)).subscribe((matches: any) => {
            if (matches && matches.length > 0) {
                this.selectedmarket = matches;
            }
        });

        if (!websiteSettings.data.isSocketRateEnabled && this.selectedmarket && this.selectedmarket.length > 0) {
            const centralizationIds = this.selectedmarket.map(match => match.appCentralizationID).toString();
            this.sessionService.removeAllCentralGroup(centralizationIds);
        }
    }
    onTcClick() {
        this.rulesRef.openPopup();
    }
    onOneClick() {
        this.oneclickRef.openPopup();
    }
    onChange(isChecked: boolean) {
        if (isChecked) {
            this.isCheckedOneClickBet = true;
            this.commonService.setCookieValue('isCheckedOneClickBet', JSON.stringify({ isCheckedOneClickBet: true }));
            const oneClickSelectedStake = this.commonService.getCookieValue('oneClickSelectedStake');
            if (oneClickSelectedStake != null && oneClickSelectedStake != undefined && oneClickSelectedStake != '' && JSON.parse(oneClickSelectedStake)) {
              this.selectedStakeValue = JSON.parse(oneClickSelectedStake);
            } else {
              const oneClickStakeValuesData = this.commonService.getCookieValue('oneClickStakeValuesData');
              if (oneClickStakeValuesData != null && oneClickStakeValuesData != undefined && oneClickStakeValuesData != '' && JSON.parse(oneClickStakeValuesData)) {
                this.oneClickStakeData = JSON.parse(oneClickStakeValuesData);
              } else {
                this.oneClickStakeData = apiEndPointData.data.oneClickStakeValue;
              }
              this.commonService.setCookieValue('oneClickSelectedStake', JSON.stringify(this.oneClickStakeData[0]));
              this.commonService.setOneClickStakeStatus(this.oneClickStakeData[0]);
            }
          } else {
            this.isCheckedOneClickBet = false;
            this.commonService.setCookieValue('isCheckedOneClickBet', JSON.stringify({ isCheckedOneClickBet: false }));
          }
    }
    ngOnDestroy() { }
}
