import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { PlaceBet } from '@clientApp-core/models/bet/place-bet.model';

export class BetRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}


export function mapPlaceBetRequest(placeBet: PlaceBet): BaseRequest<any> {
    const body = {
        BetId: placeBet.betId,
        BetDetailId: placeBet.betDetailId,
        IsBack: placeBet.isBack,
        Rate: placeBet.rate,
        Stake: placeBet.stake,
        Fancytype: placeBet.fancyType,
        Point: placeBet.point,
        placeFrom: placeBet.placeFrom,
        deviceinfo: placeBet.deviceinfo
    };
    const request = new BetRequest(body, {}, mapPlaceBetEndPoint());
    return request;
}
export function mapDeleteBetRequest(betId: number, clientId: number): BaseRequest<any> {
    const request = new BetRequest({}, { betId: betId, clientBetId: clientId }, mapDeleteBetEndPoint());
    return request;
}

export function mapBalanceBetRequest(): BaseRequest<any> {
    const request = new BetRequest({}, {}, mapGetBalanceEndPoint());
    return request;
}

export function mapLiabilityBetRequest(): BaseRequest<any> {
    const request = new BetRequest({}, {}, mapGetLiabilityEndPoint());
    return request;
}
// export function mapLastShowTimeMarkeRequest(CentralizationID: string): BaseRequest<any> {
//     const queryParameter = { CentralizationID: CentralizationID };
//     const request = new BetRequest({}, queryParameter, mapGetLastShowTimeMarketEndPoint());
//     return request;
// }
export function mapPlaceBetEndPoint(): string {
    const endPointUrl = 'api/Bet/PlaceBet';
    return endPointUrl;
}

export function mapDeleteBetEndPoint(): string {
    const endPointUrl = 'api/Bet/UnMatchedBet';
    return endPointUrl;
}

export function mapGetBalanceEndPoint(): string {
    const endPointUrl = 'api/Chip/Client/Balance';
    return endPointUrl;
}
export function mapGetLiabilityEndPoint(): string {
    const endPointUrl = 'api/Market/Active/GetLiabilityWiseRecord';
    return endPointUrl;
}
// export function mapGetLastShowTimeMarketEndPoint(): string {
//     const endPointUrl = 'api/Bet/LastShowTimeMarket';
//     return endPointUrl;
// }
