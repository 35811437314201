import {
  Component, OnInit, OnDestroy,
  ViewChild, Input, AfterViewInit, OnChanges
} from '@angular/core';
declare var $: any;
import { Store } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import { Router } from '@angular/router';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import * as SelectedMarket from '@clientApp-store/selected-market/actions/selected-market.actions'; 
declare var _: any;
import { Subscription, throwError } from 'rxjs';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { LivecasinoComponent } from '@clientApp-shared/components/live-casino/livecasino.component';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { CasinoService } from '@clientApp-core/services/casino/casino.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { AuthFacadeService, casinoGameMenuSettings, websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';
import * as M from "materialize-css/dist/js/materialize";
import { FanceType } from '@clientApp-core/enums/market-fancy.type';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'pb-left-sidebar',
  templateUrl: './left.sidebar.component.html',
  styleUrls: ['./left.sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit,OnChanges,AfterViewInit, OnDestroy {
  @Input() config: any;
  @Input() allMatches: any;
  @Input() markets: any;
  user: any;
  currentMenuIndex: number = 0;
  currentTournamentIndex:number = 0;
  oldwebsite = '';
  showCasinoGames: boolean;
  casinoGameMenu = [];
  inPlayCount: number;
  slinky: any;
  selectedSport: string;
  subMatchCat: Subscription;
  excludeSport = [];
  excludeSports = [];
  excludeSportsMarket = [];
  isListwithTournament:boolean;

  @ViewChild('casino', { static: true }) casinoRef: LivecasinoComponent;
  constructor(private store: Store<ParkBetState>
    , private router: Router
    , private authService: AuthFacadeService
    , private sessionService: SessionService
    , private storeService: StoreService
    , private betService: BetFacadeService
    , private commonService: CommonService
    , private deviceInfoService: DeviceInfoService
    , private casinoService: CasinoService
    , private nodeCentralService: NodeCentralService) {
  }

  ngOnInit() {
    this.getConfig();
    this.getCasinoConfig();
    this.excludeSports = apiEndPointData.data.excludeSports;
    this.excludeSport = this.excludeSports.map(x => x.name);
    this.casinoGameMenu = websiteSettings.data.casinoMenu;
    this.selectedSport = this.commonService.selectedTab;
    this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
    this.subMatchCat = this.commonService.getCategorieWiseMatches().subscribe(selectedSport => {
      this.selectedSport = selectedSport;
    });

  }
  ngOnChanges() {
    this.getInPlayCount();
  }
  ngAfterViewInit() {
    var elcollapsible = document.querySelectorAll('.collapsible');
    var icollapsible = M.Collapsible.init(elcollapsible, {});
    let elsidenav = document.querySelectorAll('.sidenav');
    let isidenav = M.Sidenav.init(elsidenav, {
      closeOnClick: true 
    });
  }
  getInPlayCount() {
    if (this.allMatches && this.allMatches.length > 0) {
      let InPlayMatches = [];
      const excludeRacingSports = _.filter(this.allMatches, (v) => !_.includes(this.excludeSport, v.appSport));
      if (websiteSettings.data.isShowMarketOddsWiseInPlay) {
        InPlayMatches = excludeRacingSports.filter((market: any) => {
          return market.appFancyType === FanceType.Market && market.appIsInPlay
        });
      } else if (websiteSettings.data.isShowMarketBookManulWiseInPlay) {
        InPlayMatches = excludeRacingSports.filter((market: any) => {
          return (market.appFancyType === FanceType.Market || market.appFancyType === FanceType.Bookmakers || market.appFancyType === FanceType.ManualOdds) && market.appIsInPlay
        });
      } else if (websiteSettings.data.isShowAnyMarketWiseInPlay) {
        InPlayMatches = excludeRacingSports.filter((market: any) => {
          return market.appIsInPlay
        });
      } else {
        InPlayMatches = excludeRacingSports.filter((market: any) => {
          return market.appFancyType !== FanceType.AdvanceSession && market.appIsInPlay
        });
      }
      // const inPlayMatches = this.allMatches.filter(x => { return x.appIsInPlay === true && x.appFancyType !== FanceType.AdvanceSession })
       const uniqueInPlayMatches = _.uniqBy(InPlayMatches, 'appMatchID');
      if (uniqueInPlayMatches && uniqueInPlayMatches.length > 0) {
        this.inPlayCount = uniqueInPlayMatches.length;
      }
    }
  }
  getConfig() {
    this.authService.getConfig$()
      .pipe(
        untilDestroyed(this),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.excludeSports = apiEndPointData.data.excludeSports;
          this.excludeSport = this.excludeSports.map(x => x.name);
          this.casinoGameMenu = casinoGameMenuSettings.data.casinoMenu;
          this.selectedSport = this.commonService.selectedTab;
          this.isListwithTournament = websiteSettings.data.isLeftSideBarTournamentView;
          this.commonService.configData = response;
        }
      }, err => console.log('getConfig', err));
  }
  getCasinoConfig() {
    this.authService.getCasinoConfig$()
      .pipe(
        untilDestroyed(this),
        catchError(err => throwError(err))
      ).subscribe(response => {
        if (response) {
          this.casinoGameMenu = casinoGameMenuSettings.data.casinoMenu;
        }
      }, err => console.log('getCasinoConfig', err));
  }
  setIndex(index: number, tab: string) {
    this.selectedSport = tab;
    this.commonService.selectedTab = tab;
    this.commonService.gameraceStatus = false;
    this.commonService.setLoadingCategoriWiseMatches(tab);
    document.getElementById('divMultimarket').classList.remove('active');
    localStorage.setItem('is_MultiPin', JSON.stringify({ isMultiPin: false }));
    this.router.navigateByUrl('/home');
  }
  onMarketClick(match: any) {
    if (!this.deviceInfoService.isMobile()) {
      if (websiteSettings.data.isShowDashboardRate) {
        this.removeCentralGroup(match.values);
      }
    } else {
      $("#wrapper").toggleClass("toggled");
    }
    this.currentMenuIndex = 0;
    this.currentTournamentIndex = 0;
    this.storeService.removeAllStoreData();
    const stake = new Stake();
    stake.closeMe = true;
    this.betService.setStake().next(stake);
    const selectedMatch = [];
    selectedMatch.push(match.values[0].appMatchID);
    localStorage.removeItem('selected_matches');
    localStorage.removeItem('selected_betId');
    localStorage.setItem('selected_matches', JSON.stringify(selectedMatch));
    this.commonService.setSelectedTab(match.values[0].appSport);
    const centralizationIds = match.values.map(x => x.appCentralizationID).toString();
    if (websiteSettings.data.isSocketRateEnabled) {
      this.nodeCentralService.addnewMarket(centralizationIds);
    } else {
      this.sessionService.joinMultiCentralGroup(centralizationIds);
    }
    match.values.forEach(market => {
      this.sessionService.addMarketGroup('', 3, market.appMatchID, market.appBetID, market.appCentralizationID, 0, 0, 0);
      this.store.dispatch(SelectedMarket.UpsertSelectedMarket({ market: market }));
    });
    this.router.navigateByUrl('/market');
  }
  removeCentralGroup(matches: any[]) {
    if (!websiteSettings.data.isSocketRateEnabled && this.allMatches && this.allMatches.length > 0) {
      const centralizationIds = this.allMatches.map(match => match.appCentralizationID);
      const SelectedcentralizationIds = matches.map(match => match.appCentralizationID).toString();
      for (let i = 0; i < centralizationIds.length; i++) {
        if (!SelectedcentralizationIds.includes(centralizationIds[i])) {
          this.sessionService.removeCentralGroup(centralizationIds[i].toString());
        }
      }
      // this.betService.LastShowTimeMarket(SelectedcentralizationIds);
    }
  }
  removeAllGroup() {
    if (!websiteSettings.data.isSocketRateEnabled && this.allMatches && this.allMatches.length > 0) {
      const centralizationIds = this.allMatches.map(match => match.appCentralizationID).toString();
      this.sessionService.removeAllCentralGroup(centralizationIds);
    }
  }
  onCasinoGameClick(param) {
     this.commonService.setLoadingStatus(true);
     this.casinoService.getCasinoToken(param);
    }
  onCasinoClick() {
    this.casinoRef.openPopup();
    this.commonService.setLoadingStatus(true);
  }

  ngOnDestroy() { 
    this.subMatchCat.unsubscribe();
  }
}
