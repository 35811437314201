import { BaseRequest } from '@clientApp-core/models/base/base-request.model';
import { Market } from '@clientApp-core/models/market/market.model';
import { MarketCashout } from '@clientApp-core/models/market/market-cashout.model';

export class MatchRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
}

export function mapBetRequest(marketList: Market[], betId?: any): BaseRequest<any> {
    let betIds;
    if (betId) {
        betIds = betId.toString();
    } else {
        betIds = marketList.map((market) => market.appBetID).join(',');
    }
    const body = { betIds: betIds,
                   matchBetCount: 0,
                   betCount: 0,
                   overFlowCount: 0,
                   IsLoad: true
                 };
    const request = new MatchRequest(body, {}, mapBetEndpoint());
    return request;
}

export function mapMatchWiseMarketRequest(matchId: number): BaseRequest<any> {
    const params = {"matchId": matchId.toString() };
    const request = new MatchRequest(params,{}, mapMatchWiseMarketEndpoint());
    return request;
}
export function mapPostCashout(marketCashout: MarketCashout): BaseRequest<any> {
    const params = {
        betId: marketCashout.betId,
        betDetailId: marketCashout.betDetailID,
        isBack: marketCashout.isback,
        rate: marketCashout.rate,
        stake: marketCashout.stack,
        fancyType: marketCashout.fancyType,
        point: marketCashout.point,
        p1: marketCashout.p1,
        p2: marketCashout.p2,
        ltp: marketCashout.ltp,
        plCashout: marketCashout.plCashout,
        DeviceInfo: marketCashout.DeviceInfo
    };
    const request = new MatchRequest(params, {}, mapPostCashOutEndpoint());
    return request;
}
export function mapGetClientLimitRequest(): BaseRequest<any> {
    const request = new MatchRequest({}, {}, mapGetClientLimitEndpoint());
    return request;
}

export function mapBetEndpoint(): string {
    const endPointUrl = 'api/Bet/Matched/List';
    return endPointUrl;
}

export function mapMatchWiseMarketEndpoint(): string {
    const endPointUrl = 'api/Market/MatchWiseMarketData';
    return endPointUrl;
}
export function mapPostCashOutEndpoint(): string {
    const endPointUrl = 'api/Cashout/InsertPlaceBetCashout';
    return endPointUrl;
}
export function mapGetClientLimitEndpoint(): string {
    const endPointUrl = 'api/Market/GetClientLimit';
    return endPointUrl;
}
