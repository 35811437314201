<div id="liability" class="modal modal-fixed-footer" #liability>
  <div class="modal-header">
    <h5>Liability</h5>
    <span class="modal-close" (click)="hideModal()"><i class="small material-icons">cancel</i></span>
  </div>
  <div class="modal-content">
    <!-- <h5>Liability</h5>
    <span class="modal-btn-close" (click)="hideModal()"><i class="small material-icons">cancel</i></span> -->
    <div class="modal-body">
      <div class="liability-table">
        <table class="table highlight">
          <thead>
            <tr>
              <th>Date</th>
              <th>Market</th>
              <th>Liability</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of liabilityData">
              <td>{{item.appDate | date:'dd-MM-yyy hh:mm a'}}</td>
              <td>
                <a class="cursor-pointer" (click)="onMarketSelect(item)">
                  {{item.appSport}} - {{item.appTournament}} - {{item.appMatch}} - {{item.appbetname}}
                </a>
              </td>
              <td style="text-align:right">
                {{item.appLiability}}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td style="text-align:right">Total Liability</td>
              <td style="text-align:right">{{totalLiability | number:'1.2-2'}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <a class="modal-close waves-effect waves-light red btn" (click)="hideModal()">Close</a>
  </div> -->
</div>