import { Routes } from '@angular/router';
import { LoginLayoutComponent } from './features/layouts/login-layout/login-layout.component';
import { HomeLayoutComponent } from './features/layouts/home-layout/home-layout.component';
import { LoggedInUserGuard } from '@clientApp-core/gaurds/logged-in-user.guard';

export const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [LoggedInUserGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'market',
        loadChildren: () => import('./features/market/market.module').then(m => m.MarketModule)
      },
      {
        path: 'casinoGame/:id',
        loadChildren: () => import('./features/casino-games/casino-games.module').then(m => m.CasinoGamesModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./features/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'chip-settings',
        loadChildren: () => import('./features/chip-settings/chip-settings.module').then(m => m.ChipSettingsModule)
      },
      {
        path: 'account-statement',
        loadChildren: () => import('./features/account-statement/account-statement.module').then(m => m.AccountStatementModule)
      },
      {
        path: 'profit-and-loss',
        loadChildren: () => import('./features/profit-and-loss/profit-and-loss.module').then(m => m.ProfitAndLossModule)
      },
      {
        path: 'results',
        loadChildren: () => import('./features/results/results.module').then(m => m.ResultsModule)
      }
    ]
  },
    {
      path: '',
      component: LoginLayoutComponent, data: { title: 'Login - PrimeExch99' },
      children: [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
        {
          path: 'login',
          loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
        },
      ]
    },
    {
      path: '**',
      redirectTo: 'login'
    }
  ];