
import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import {
  mapLoginRequest, mapIsAuthorizedRequest,
  mapConfigRequest,
  mapLogoutRequest,
  CaptchaCodeRequest,
  mapCasinoConfigRequest
} from '@clientApp-core/services/authentication/authentication-helper.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';


@Injectable()
export class AuthFacadeService {
  private _configurationSubject = new Subject<any>();
  private _casinoConfigurationSubject = new Subject<any>();
  private _ResetPasswordSubject = new Subject<boolean>();
  constructor(private httpClient: HttpClient) { }

  LogIn$(name: string, password: string, Captcha: string, RequestId: string): Observable<any> {
    const logInRequest = mapLoginRequest(name, password, Captcha, RequestId);
    return this.httpClient.post(this.getBaseUrl(logInRequest), logInRequest.body, { params: logInRequest.queryParameter })
      .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
  }
  LogOut$() {
    const logOutRequest = mapLogoutRequest();
    this.httpClient.post(this.getBaseUrl(logOutRequest), logOutRequest.body).pipe(catchError(err => throwError(err)))
      .subscribe((response) => { }, err => console.log('LogOut', err));
  }
  IsAuthorized$() {
    const isAuthorizedRequest = mapIsAuthorizedRequest();
    return this.httpClient.post(this.getBaseUrl(isAuthorizedRequest), isAuthorizedRequest.body)
      .pipe(catchError(err => throwError(err)));
  }
  getConfig$(): Observable<any> {
    return this._configurationSubject.asObservable();
  }
  getCasinoConfig$(): Observable<any> {
    return this._casinoConfigurationSubject.asObservable();
  }
  getConfig() {
    const configRequest = mapConfigRequest();
    return this.httpClient.post(this.getBaseUrl(configRequest), configRequest.body)
      .pipe(map((data: any) => {
        const configData = this.serializeResponse(data.result);
        websiteSettings.data = configData;
        return configData;
      }), catchError(err => { return throwError(err) }))
      .subscribe((response) => this._configurationSubject.next(response), err => console.log('getConfig', err));
  }
  getCasinoConfig() {
    const configRequest = mapCasinoConfigRequest();
    return this.httpClient.post(this.getBaseUrl(configRequest), configRequest.body)
      .pipe(map((data: any) => {
        // const configData = this.serializeResponse(data.result);
        casinoGameMenuSettings.data = data.result;
        return data.result;
      }), catchError(err => { return throwError(err) }))
      .subscribe((response) => this._casinoConfigurationSubject.next(response), err => console.log('getCasinoConfig', err));
  }
  getCaptchaCode() {
    const CaptchaRequest = CaptchaCodeRequest();
    return this.httpClient.post(this.getBaseUrl(CaptchaRequest), CaptchaRequest.body).pipe(catchError(err => throwError(err)));
  }
  getResetPasswordStatus$(): Observable<boolean> {
    return this._ResetPasswordSubject.asObservable();
  }
  setResetPasswordStatus(): Subject<boolean> {
    return this._ResetPasswordSubject;
  }
  getBaseUrl(item: any): string {
    let baseUrl: string;
    if (item.baseUrl) {
      baseUrl = `${item.baseUrl}${item.endPoint}`;
    } else {
      baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
    }
    return baseUrl;
  }
  serializeResponse(response: any) {
    const object = {};
    // if (response && response.configData && response.configData.length > 0) {
    //   response.configData.forEach(data => {
    //     const obj = data.split('==');
    //     object[obj[0]] = obj[1];

    //   });
    // }
    // for (let key in response) {
    //   if (response.hasOwnProperty(key)
    //       && key !== 'appAppConfig'
    //       && key !== 'appWebConfig'
    //       && key !== 'marketType'
    //       && key !== 'casinoMenu'
    //       && key !== 'casinoGameCategory'
    //       && key !== 'permissions') {
    //     object[key] = response[key];
    //   }
    // }
    for (let key in response.general) {
        if (response.general.hasOwnProperty(key)) {
          object[key] = response.general[key];
      }
    }
    for (let key in response.appWebConfig) {
      if (response.appWebConfig.hasOwnProperty(key)) {
        object[key] = response.appWebConfig[key];
      }
    }
    for (let key in response.permissions) {
      if (response.permissions.hasOwnProperty(key)) {
        object[key] = response.permissions[key];
      }
    }
    if (response && response.casinoMenu && response.casinoMenu !== null && response.casinoMenu !== undefined) {
      object['casinoMenu'] = response.casinoMenu;
    }
    if (response && response.casinoGameCategory && response.casinoGameCategory !== null && response.casinoGameCategory !== undefined) {
      object['casinoGameCategory'] = response.casinoGameCategory;
    }
    if (response && response.marketType && response.marketType !== null) {
      try {
        object['marketType'] = JSON.parse(response.marketType);
      } catch (e) {
        console.log('marketType parse error ', e);
        object['marketType'] = [];
      }
    }
    return object;
  }
}
export const websiteSettings: any = {
  data: ''
};
export const casinoGameMenuSettings: any = {
  data: ''
};

