import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiEndPointData } from '../config/connfig.service';
import { HttpClient } from '@angular/common/http';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class ChipRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}

export function mapChipRequest(): BaseRequest<any> {
    const request = new ChipRequest({}, {}, mapChipEndpoint(''));
    return request;
}
export function mapSaveCustomChipRequest(payload: any): BaseRequest<any> {
    const request = new ChipRequest(payload, {}, mapSaveCustomChipEndpoint(''));
    return request;
}
export function mapChipEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Chip/Client/Costume';
    return endPointUrl;
}
export function mapSaveCustomChipEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Chip/Client/SaveCostume';
    return endPointUrl;
}

@Injectable()
export class ChipFacadeService {
    constructor(private httpClient: HttpClient) { }

    getChips$(): Observable<any> {
        const chipRequest = mapChipRequest();
        return this.httpClient.post(this.getBaseUrl(chipRequest), { params: chipRequest.queryParameter })
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }

    saveCustomChips$(payload: any): Observable<any> {
        const chipRequest = mapSaveCustomChipRequest(payload);
        return this.httpClient.post(this.getBaseUrl(chipRequest), chipRequest.body, { params: chipRequest.queryParameter })
            .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
    }
}


