import { Market } from '@clientApp-core/models/market/market.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface SelectedMarketState extends EntityState<Market> {
 selectedMarketId: string | null;
}

export const marketAdapter: EntityAdapter<Market> = createEntityAdapter<Market>({
    selectId: (market: Market) => market.appBetID,
    sortComparer: false,
  });


  export const initialState: SelectedMarketState = marketAdapter.getInitialState({
    selectedMarketId: null,
  });

export const getSelectedId = (state: SelectedMarketState) => state.selectedMarketId;

