
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularDraggableModule } from 'angular2-draggable';

import { LeftSidebarComponent } from '@clientApp-shared/components/sidebar/left/left.sidebar.component';
import { HeaderComponent } from '@clientApp-shared/components/header/header.component';
import { CheckFancyTypePipe } from '@clientApp-shared/pipes/check-fancy-type.pipe';
import { HighlightDirective } from '@clientApp-shared/directive/highlight.directive';
import { FocusDirective } from '@clientApp-shared/directive/focus.directive';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarComponent } from '@clientApp-shared/components/loading-bar/loading-bar.component';
import { CountDownComponent } from '@clientApp-shared/components/count-down/count-down.component';
import { RulesComponent } from '@clientApp-shared/components/rules/rules.component';
import { LiabilityComponent } from '@clientApp-shared/components/liability/liability.component';
import { AudioPlayerComponent } from '@clientApp-shared/components/audio-player/audio-player.component';
import { OverBallPipe } from '@clientApp-shared/pipes/over-ball.pipe'
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SafePipe } from '@clientApp-shared/pipes/safe.pipe';
import { LivecasinoComponent } from '@clientApp-shared/components/live-casino/livecasino.component';
import { VeronicapopupComponent } from './components/veronicapopup/veronicapopup.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CheckInplayTypePipe } from './pipes/check-inplay-type.pipe';
import { MultiPinDirective } from '@clientApp-shared/directive/multipin.directive';
import { FooterComponent } from './components/footer/footer.component';
import { OneClickComponent } from './components/one-click/one-click.component';
import { NameFormatePipe } from './pipes/name-formate.pipe';

@NgModule({
  imports: [
    /** Common Angular Module */
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 4,
      timeOut: 3000,
      preventDuplicates: true
    }),
    AngularDraggableModule,
    DeviceDetectorModule.forRoot(),
  ],
  declarations: [

    /** Componenets */
    HeaderComponent,
    LeftSidebarComponent,
    AudioPlayerComponent,
    LoadingBarComponent,
    CountDownComponent,
    RulesComponent,
    LiabilityComponent,
    LivecasinoComponent,
    VeronicapopupComponent,
    ResetPasswordComponent,

    /** Directives */
    HighlightDirective,
    FocusDirective,
    MultiPinDirective,

    /** Pipes */
    CheckFancyTypePipe,
    OverBallPipe,
    SafePipe,
    CheckInplayTypePipe,
    NameFormatePipe,
    FooterComponent,
    OneClickComponent,

  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AngularDraggableModule,

    /** Pipes */
    CheckFancyTypePipe,
    OverBallPipe,
    SafePipe,
    CheckInplayTypePipe,
    NameFormatePipe,

    /** Directives */
    HighlightDirective,
    FocusDirective,
    MultiPinDirective,

    /** Componenets */
    HeaderComponent,
    LeftSidebarComponent,
    AudioPlayerComponent,
    LoadingBarComponent,
    CountDownComponent,
    RulesComponent,
    LiabilityComponent,
    LivecasinoComponent,
    VeronicapopupComponent,
    ResetPasswordComponent,
    FooterComponent,
    OneClickComponent
  ]
})

export class SharedModule { }
