import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import { Observable, Subject, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
declare var $: any;
import { Market } from '@clientApp-core/models/market/market.model';
import * as fromSelectedMarket from '@clientApp-store/selected-market/selectors/selected-market.selectors';
import { ConnectionState, SignalrConnectionState } from '@clientApp-core/enums/connectionState-type';
import { CommonService } from '../common/common.service';
import { websiteSettings } from '../authentication/authentication-facade.service';
import { MarketFacadeService } from '../market/market-facade.service';
import { apiEndPointData } from '../config/connfig.service';
import * as signalR from '@microsoft/signalr';

@Injectable()
export class SessionService {

  private _centralHubConnectionSubject = new Subject<any>();
  private _marketConnectionSubject = new Subject<any>();
  private _centeralHubConnection: any;
  private _marketHubConnection: any;
  // private _centeralHubConnection: any;
  // private _marketHubConnection: any;
  private _isUserInitiated: boolean;
  private _hubState = {
    connected: 1
  };
  selectedMarkets: Market[];
  private centralHubStateSubject = new Subject<boolean>();
  private marketHubStateSubject = new Subject<boolean>();
  reconnectCenteralCount = 0;
  reconnectMarketCount = 0;
  constructor(private store: Store<ParkBetState>, private commonService: CommonService, private marketFacadeService: MarketFacadeService) {
  }

  get centeralHubState(): number {
    if (websiteSettings.data.isSocketRateEnabled) {
      return 1;
    } else {
      if (this._centeralHubConnection && this._centeralHubConnection.state) {
        if (this._centeralHubConnection.state == 'Connected') {
          return SignalrConnectionState.Connected;
        } else if (this._centeralHubConnection.state == 'Disconnected') {
          return SignalrConnectionState.Disconnected;
        } else if (this._centeralHubConnection.state == 'Reconnecting') {
          return SignalrConnectionState.Reconnecting;
        } else {
          return SignalrConnectionState.Connecting;
        } 
       
      }
    }
  }

  get marketHubState(): number {
    if (this._marketHubConnection && this._marketHubConnection.state) {
      if (this._marketHubConnection.state == 'Connected') {
        return SignalrConnectionState.Connected;
      } else if (this._marketHubConnection.state == 'Disconnected') {
        return SignalrConnectionState.Disconnected;
      } else if (this._marketHubConnection.state == 'Reconnecting') {
        return SignalrConnectionState.Reconnecting;
      } else {
        return SignalrConnectionState.Connecting;
      } 
    }
  }

  get centralHubConnection(): Observable<any> {
    return this._centralHubConnectionSubject.asObservable();
  }

  get marketHubConnection(): Observable<any> {
    return this._marketConnectionSubject.asObservable();
  }
  joinCentralDashboardGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('ConnectDSRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('ConnectDSRate', group);
        });
      }
    }
  }

  joinCentralGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('ConnectMarketRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('ConnectMarketRate', group);
        });
      }
    }
  }

  joinMultiCentralGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('ConnectMarketRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('ConnectMarketRate', group);
        });
      }
    }
  }

  removeAllCentralGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('DisconnectMarketRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('DisconnectMarketRate', group);
        });
      }
    }
  }
  removeCentralDashboardGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('DisconnectDSRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('DisconnectDSRate', group);
        });
      }
    }
  }
  removeCentralGroup(group: string): void {
    const self = this;
    if (!websiteSettings.data.isSocketRateEnabled) {
      if (this.centeralHubState === this._hubState.connected) {
        this._centeralHubConnection.invoke('DisconnectMarketRate', group);
      } else {
        this.startCentralHubConnection$().pipe(take(1)).subscribe(() => {
          self._centeralHubConnection.invoke('DisconnectMarketRate', group);
        });
      }
    }
  }

  joinMarketGroup(ConnectionInfo: any): void {
    const self = this;
    if (this.marketHubState === this._hubState.connected) {
      this._marketHubConnection.invoke('addGroup', ConnectionInfo);
    } else {
      this.startMarketHubConnection$().pipe(take(1)).subscribe(() => {
        self._marketHubConnection.invoke('addGroup', ConnectionInfo);
      });
    }
  }
  addConnection() {
    const self = this;
    const user = JSON.parse(localStorage.getItem('token'));
    if (user != null) {
      if (this.marketHubState === this._hubState.connected) {
        this._marketHubConnection.invoke('addConnection', Number(user.id), (user.distributor),
          Number(user.masterDistributor));
      } else {
        this.startMarketHubConnection$().pipe(take(1)).subscribe(() => {
          this._marketHubConnection.invoke('addConnection', Number(user.id), Number(user.distributor),
            Number(user.masterDistributor));
        });
      }
    }
  }
  joinScoreCentralGroup(group: any): void {
    const self = this;
      if (this.marketHubState === this._hubState.connected) {
        this._marketHubConnection.invoke('connectScore', group);
      } else {
        this.startMarketHubConnection$().pipe(take(1)).subscribe(() => {
          self._marketHubConnection.invoke('connectScore', group);
        });
      }
  }
  removeScoreCentralGroup(group: any): void {
    const self = this;
      if (this.marketHubState === this._hubState.connected) {
        this._marketHubConnection.invoke('disconnectScore', group);
      } else {
        this.startMarketHubConnection$().pipe(take(1)).subscribe(() => {
          self._marketHubConnection.invoke('disconnectScore', group);
        });
      }
  }
  addMGroup() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (user != null) {
      this.addMarketGroup('', 0, 0, 0, 0, user.id, 0, 0);
    }
  }

  addMarketGroup(ConnectionId: string, ConnectionType: number, MatchID: number,
    BetID: number, CentralizationID: any, ClientID: number, DistributorID: number, MasterDistributorID: number) {
    const ConnectionInfo = {
      'ConnectionId': ConnectionId,
      'ConnectionType': ConnectionType,
      'MatchID': MatchID,
      'BetID': BetID,
      'CentralizationID': CentralizationID,
      'ClientID': ClientID,
      'DistributorID': 0,
      'MasterDistributorID': 0
    };
    this.joinMarketGroup(ConnectionInfo);
  }

  startHubConnections() {
    if (!websiteSettings.data.isSocketRateEnabled) {
      this.setCentralHubConnection();
      this.startCentralHubConnection();
    }
    this.setMarketHubConnection();
    this.startMarketHubConnection();

  }
  connectCentralHubConnection() {
    this.setCentralHubConnection();
    this.startCentralHubConnection();
  }
  stopHubConnections() {
    if (!websiteSettings.data.isSocketRateEnabled) {
      this.stopCenteralHubConnection();
    }
    this.stopMarketHubConnection();
  }

  private setMarketHubConnection() {
    try {
      let signalREndPoint;
      signalREndPoint = this.commonService.configData.webNotificationSignalr;
      // signalREndPoint = apiEndPointData.data.webNotificationSignalr;
      this._marketHubConnection = new signalR.HubConnectionBuilder()
                                  .withUrl(signalREndPoint)
                                  .build();
      this._marketConnectionSubject.next(this._marketHubConnection);
    // this._marketHubConnection = $.hubConnection('/signalr', { useDefaultPath: false });
    // this._marketHubConnection.url = signalREndPoint;
    // this._marketHubConnection = this._marketHubConnection.createHubProxy('notification');
    //   this._marketConnectionSubject.next(this._marketHubConnection);
    } catch (ex) {
      console.log('setMarketHubConnection', ex);
    }
  }

  private setCentralHubConnection() {
    try {
    let signalREndPoint;
        signalREndPoint = this.commonService.configData?.webRateSignalr;
      if (signalREndPoint == null || signalREndPoint == undefined || signalREndPoint == '') {
        signalREndPoint = apiEndPointData.data.webRateSignalr;
      }
      // signalREndPoint = apiEndPointData.data.webRateSignalr;
      this._centeralHubConnection = new signalR.HubConnectionBuilder()
                                    .withUrl(signalREndPoint)
                                    .build();
      this._centralHubConnectionSubject.next(this._centeralHubConnection);
    // this._centeralHubConnection = $.hubConnection('/signalr', { useDefaultPath: false });
    // this._centeralHubConnection.url = signalREndPoint;
    // this._centeralHubConnection = this._centeralHubConnection.createHubProxy('centerRate');
    //   this._centralHubConnectionSubject.next(this._centeralHubConnection);
    } catch (ex) {
      console.log('setCentralHubConnection', ex);
    }
  }

  private stopCenteralHubConnection() {
    if (this._centeralHubConnection != null) {
      this._centeralHubConnection.stop();
      this._isUserInitiated = true;
    }
  }

  private stopMarketHubConnection() {
    if (this._marketHubConnection != null) {
      this._marketHubConnection.stop();
      this._isUserInitiated = true;
    }
  }
  private startCentralHubConnection$(): Observable<boolean> {
    return this.centralHubStateSubject.asObservable();
  }

  private startCentralHubConnection() {
    const self = this;
    if (this.centeralHubState !== this._hubState.connected) {
      this._centeralHubConnection.start()
        .then(function () {
          console.log('Centeral hub connected.');
          self.getSelectedMarket();
          self.ReconnectionGroup();
          self.centralHubStateSubject.next(true);
        })
        .catch(function (ex) {
          if (self.reconnectCenteralCount < 3) {
            console.log('Error while connecting centeral hub', ex);
            self.reconnectCenteralCount = self.reconnectCenteralCount + 1;
            // setTimeout(() => self.startCentralHubConnection(), 5000);
          }
        });
        this._centeralHubConnection.onclose(() => {
          self.getSelectedMarket();
          self.startCentralHubConnection();
          self.ReconnectionGroup();
        });
        // self._centeralHubConnection.stateChanged(function (change) {
        //   if (change.newState === ConnectionState.reconnecting) {
        //     self._isUserInitiated = false;
        //     self._centeralHubConnection.reconnected(function () {
        //             console.log('Centeral hub reconnected.');
        //             self.getSelectedMarket();
        //             self.ReconnectionGroup();
        //             self._isUserInitiated = true;
        //       });
        //   } else if (change.newState === ConnectionState.connected) {
        //     //  console.log('Central connected');
        //   } else if (change.newState === ConnectionState.disconnected) {
        //     if (self._isUserInitiated !== undefined && !self._isUserInitiated) {
        //          self.getSelectedMarket();
        //          self.startCentralHubConnection();
        //          self.ReconnectionGroup();
        //     }
        //   }
        // });
    }
  }

  private startMarketHubConnection$(): Observable<boolean> {
    return this.marketHubStateSubject.asObservable();
  }
  private startMarketHubConnection() {
    const self = this;
    if (this.marketHubState !== this._hubState.connected) {
      this._marketHubConnection.start()
        .then(function () {
          console.log('Market hub connected.');
          self.addMGroup();
          self.getSelectedMarket();
          self.ReconnectMarketGroup();
          self.marketHubStateSubject.next(true);
        })
        .catch(function (ex) {
          if (self.reconnectMarketCount < 3) {
            console.log('Error while connecting market hub', ex);
            self.reconnectMarketCount = self.reconnectMarketCount + 1;
            // setTimeout(() => self.startMarketHubConnection(), 5000);
          }
        });
        this._marketHubConnection.onclose(() => {
          self.getSelectedMarket();
          self.startMarketHubConnection();
          self.ReconnectMarketGroup();
        });
        // self._marketHubConnection.stateChanged(function (change) {
        //   if (change.newState === ConnectionState.reconnecting) {
        //     self._isUserInitiated = false;
        //     self._marketHubConnection.reconnected(function () {
        //             console.log('Market hub reconnected.');
        //             self.getSelectedMarket();
        //             self.ReconnectMarketGroup();
        //             self._isUserInitiated = true;
        //       });
        //   } else if (change.newState === ConnectionState.connected) {
        //       // console.log('Market connected');
        //   } else if (change.newState === ConnectionState.disconnected) {
        //     if (self._isUserInitiated !== undefined && !self._isUserInitiated) {
        //          self.getSelectedMarket();
        //          self.startMarketHubConnection();
        //          self.ReconnectMarketGroup();
        //     }
        //   }
        // });
    }
  }
  getSelectedMarket() {
    this.store
      .pipe(select(fromSelectedMarket.getAllMarkets), take(1), catchError(err => throwError(err)))
      .subscribe(markets => this.selectedMarkets = markets, err => console.log('getSelectedMarket', err));

  }
  ReconnectionGroup() {
    try {
      if (!(this.selectedMarkets.length <= 0 || !this.selectedMarkets)) {
        const centralizationIds = this.selectedMarkets.map(match => match.appCentralizationID).toString();
        this.joinMultiCentralGroup(centralizationIds);
      }
      // else {
      //   const allMarkets = this.marketFacadeService.marketList;
      //   if (allMarkets && allMarkets.length > 0 && websiteSettings.data.isShowDashboardRate) {
      //     const centralizationIds = allMarkets.map(match => match.appCentralizationID).toString();
      //     this.joinMultiCentralGroup(centralizationIds);
      //   }
      // }
    } catch (ex) {
      console.log('Error on Reconnection Central Group function ::' + ex.message);
    }
  }
  ReconnectMarketGroup() {
    try {
      if (!(this.selectedMarkets.length <= 0 || !this.selectedMarkets)) {
        this.selectedMarkets.forEach((matchs: any) => {
          this.addMarketGroup('', 3, matchs.appMatchID, matchs.appBetID, matchs.appCentralizationID, 0, 0, 0);
          this.joinScoreCentralGroup(matchs.eventId_BF);
        });
      }
    } catch (ex) {
      console.log('Error on Reconnect Market Group function ::' + ex.message);
    }
  }
}
