import { Injectable } from '@angular/core';

@Injectable()
export class SharedService  {
    constructor() {}
}

export function  getmarketProfitLoss(bets, betDetailId, currentBet) {
  let decLilbility = 0;
  try {

    bets = bets.filter(bet => bet.clientBetDetails.betID === currentBet.betId
      && getSomething(bet, currentBet));

    if (bets.length > 0) {
      bets.forEach(function(bet) {
        if ((bet.clientBetDetails.betDetailId === betDetailId)
         && (bet.clientBetDetails.isBack === true)
         && (bet.clientBetDetails.isMatched === true)) {
          decLilbility = decLilbility + (bet.clientBetDetails.profit);
        }
        if ((bet.clientBetDetails.betDetailId === betDetailId)
         && (bet.clientBetDetails.isBack === false)
         && (bet.clientBetDetails.isMatched === true)) {
          decLilbility = decLilbility - (bet.clientBetDetails.profit);
        }
        if ((bet.clientBetDetails.betDetailId !== betDetailId)
         && (bet.clientBetDetails.isBack === true)
         && (bet.clientBetDetails.isMatched === true)) {
          decLilbility = decLilbility - (bet.clientBetDetails.stake);
        }
        if ((bet.clientBetDetails.betDetailId !== betDetailId)
         && (bet.clientBetDetails.isBack === false)
         && (bet.clientBetDetails.isMatched === true)) {
          decLilbility = decLilbility + (bet.clientBetDetails.stake);
        }
      });
    }
  } catch (ex) {
    console.log('error getmarketPLLiability : ' + ex);
  }
  return decLilbility;
}

function getSomething(allBets: any, currentBet: any): boolean {
  return allBets.hasOwnProperty('matchBets')
      ? allBets.matchBets.betId === currentBet.betId : allBets.unMatchBets.betId === currentBet.betId;
}

