
import { Injectable } from '@angular/core';
declare var _: any;
import { Observable, Subject, ReplaySubject, throwError, BehaviorSubject } from 'rxjs';
import { take, catchError, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MarketRateService } from './market-rate.service';
import { Match } from '@clientApp-core/models/market/match.model';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { ParkBetState } from '@clientApp-store/store.state';
import * as fromMarketRate from './market-rate-helper.service';
import { Market } from '@clientApp-core/models/market/market.model';
import { MarketRates } from '@clientApp-core/models/market/market-rates.model';
import { BetSerializer } from '@clientApp-core/serializers/market/market-bet-details.serializer';
import { MarketBet } from '@clientApp-core/models/market/market-bet.model';
import { StoreService } from '@clientApp-core/services/store/store.service';
import * as fromSelectedMarket from '@clientApp-store/selected-market/selectors/selected-market.selectors';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { MarketRunner } from '@clientApp-core/models/market/market-runner.model';
import { MarketCashout } from '@clientApp-core/models/market/market-cashout.model';
import { AudioType } from '@clientApp-core/enums/audio.types';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { FanceType } from '@clientApp-core/enums/market-fancy.type';

@Injectable()
export class MarketRateFacadeService {

  private _matchSubject = new Subject<any>();
  private _runningDashboardMarketSubject = new ReplaySubject(1);
  private _runningMarketSubject = new ReplaySubject(1);
  private _centralScoreSubject = new ReplaySubject(1);
  private _adhocMatchSubject = new Subject<any>();
  private _marketCommentarySubject = new Subject<any>();
  private _marketBetListSubject = new Subject<any>();
  private _currentMarketSubject = new Subject<any>();
  private _matchWiseMarketSubject = new Subject<any>();
  private _currentMarketVolumeSubject = new BehaviorSubject<any>(null);
  private _currentMarketRunnerSubject = new BehaviorSubject<any>(null);
  private  _marketBetAllowChanges = new Subject<any>();
  private  _marketRateVolumeChanges = new Subject<any>();
  private  _inPlayChanges = new Subject<any>();
  private  _marketStatusChanges = new Subject<any>();
  private  _multiMatchWiseInfo = new Subject<any>();
  private  _marketChanges = new Subject<any>();
  private _marketCashoutSubject = new BehaviorSubject<any>(null);
  private _placedBetCashoutSubject = new Subject<any>();
  private _matchBetCountSubject = new Subject<number>();
  private _unMatchBetCountSubject = new Subject<number>();
  private _setAudioTypeSubject = new Subject<AudioType>();
  private _addNewMarketSubject = new Subject<any>();
  private _clientLimitChangesSubject = new Subject<any>();
  private _marketLimitChangesSubject = new Subject<any>();
  private _marketNewsChangesSubject = new Subject<any>();
  private _getBetListSubject = new Subject<any>();
  private _getMarketClientBetListSubject = new Subject<any>();
  private _deleteBetListSubject = new Subject<any>();
  curMatchInfo: Match[] = [];
  curMarketsVol: MarketRates[] = [];
  curMarketsRate: MarketRates[] = [];
  curMarketsRunners: MarketRunner[] = [];
  marketCashout: MarketCashout[] = [];
  marketScore: any[] = [];
  clientLimit: any;

  constructor(private httpClient: HttpClient,private marketRateService: MarketRateService
    , private marketFacadeService: MarketFacadeService
    , private sessionService: SessionService
    , private store: Store<ParkBetState>
    , private _authService: AuthFacadeService
    , private storeService: StoreService) {
    this.init();
  }
  getClientLimitChanges$(): Observable<any> {
    return this._clientLimitChangesSubject.asObservable();
  }
  getMarketLimitChanges$(): Observable<any> {
    return this._marketLimitChangesSubject.asObservable();
  }
  getBetList$(): Observable<any> {
    return this._getBetListSubject.asObservable();
  }
  deleteBetList$(): Observable<any> {
    return this._deleteBetListSubject.asObservable();
  }
  setMarketClientBetList(): Subject<any> {
    return this._getMarketClientBetListSubject;
  }
  getMarketClientBetList$(): Observable<any> {
    return this._getMarketClientBetListSubject.asObservable();
  }
  getMarketBetAllowChanges$(): Observable<any> {
    return this._marketBetAllowChanges.asObservable();
  }
  getMarketRateVolumeChanges$(): Observable<any> {
    return this._marketRateVolumeChanges.asObservable();
  }
  getMultiMatchWiseInfo$(): Observable<any> {
    return this._multiMatchWiseInfo.asObservable();
  }
  getMarketChanges$(): Observable<any> {
    return this._marketChanges.asObservable();
  }
  getInPlayChanges$(): Observable<any> {
    return this._inPlayChanges.asObservable();
  }
  getMarketStatusChanges$(): Observable<any> {
    return this._marketStatusChanges.asObservable();
  }
  getRunningDashboardMarketDetail$(): Observable<any> {
    return this._runningDashboardMarketSubject.asObservable();
  }
  getRunningMarketDetail$(): Observable<any> {
    return this._runningMarketSubject.asObservable();
  }
  getCentralScoreDetail$(): Observable<any> {
    return this._centralScoreSubject.asObservable();
  }
  getMarketCommentary$(): Observable<any> {
    return this._marketCommentarySubject.asObservable();
  }
  getAddNewMarketInfo$(): Observable<any> {
    return this._addNewMarketSubject.asObservable();
  }
  getAdhocMatchInfo$(): Observable<any> {
    return this._adhocMatchSubject.asObservable();
  }
  getMatchInfo$(): Observable<Match> {
    return this._matchSubject.asObservable();
  }
  getMarketNewsChanges$(): Observable<any> {
    return this._marketNewsChangesSubject.asObservable();
  }
  getCurrentMarketVolume$(): Observable<MarketRates[]> {
    return this._currentMarketVolumeSubject.asObservable();
  }

  getCurrentMarketRunner$(): Observable<any> {
    return this._currentMarketRunnerSubject.asObservable();
  }

  getMatchWiseMarket$(matchId: number): Observable<any> {
    this.MatchWiseMarketRequest(matchId);
    return this._matchWiseMarketSubject.asObservable();
  }
  getBetInfo$(): Observable<MarketBet[]> {
    return this._marketBetListSubject.asObservable();
  }

  getCurrentMarket$(): Observable<MarketRates[]> {
    return this._currentMarketSubject.asObservable();
  }
  getMarketCashout(): Observable<MarketCashout> {
    return this._marketCashoutSubject.asObservable();
  }

  setMarketCashout(): BehaviorSubject<MarketCashout> {
    return this._marketCashoutSubject;
  }
  getMatchBetCount$(): Observable<number>  {
    return this._matchBetCountSubject.asObservable();
  }
  setMatchBetCount(): Subject<number>  {
      return this._matchBetCountSubject;
  }
  getUnMatchBetCount$(): Observable<number>  {
    return this._unMatchBetCountSubject.asObservable();
  }
  setUnMatchBetCount(): Subject<number>  {
      return this._unMatchBetCountSubject;
  }
  getAudioType$(): Observable<AudioType> {
    return this._setAudioTypeSubject.asObservable();
  }
  setAudioType(): Subject<AudioType> {
      return this._setAudioTypeSubject;
  }
  MatchWiseMarketRequest(matchId: number): Observable<any> {
    const matchWiseMarketRequest = fromMarketRate.mapMatchWiseMarketRequest(matchId);
    return this.httpClient
     .post(this.getBaseUrl(matchWiseMarketRequest),matchWiseMarketRequest.body, {params:matchWiseMarketRequest.queryParameter})
     .pipe(catchError(err => throwError(err)));
  }

  getBetInfo(betId?: any) {
    const betRequest = fromMarketRate.mapBetRequest(this.getSelectedMarket(),betId);
    const betSerializer = new BetSerializer();
    return this.marketRateService
    .post<any, MarketBet[]>(betRequest, betSerializer)
    .pipe(catchError(err => throwError(err)))
    .subscribe((betInfo) => this._marketBetListSubject.next(betInfo), err => console.log('MarketBetInfo', err));
  }
  checkCurrentLogin() {
    this._authService.IsAuthorized$().pipe(take(1)).subscribe((data:any) => {
      if (data.isAuthorized) {
        if (data.isNewToken) {
          const user = JSON.parse(localStorage.getItem('token'));
          if (user != null) {
           user.access_token = data.token;
           user.expireOn = data.expireOn;
           localStorage.setItem('token', JSON.stringify(user));
          }
        }
  } else {
    this.storeService.clearStore(); // logout
  }
    }, error => {this.storeService.clearStore();  console.log('checkCurrentLogin', error); } );
  }

private  getSelectedMarket(): Market[] {
  let selectedMarkets: Market[] ;
  this.store
  .pipe(select(fromSelectedMarket.getAllMarkets), take(1), catchError(err => throwError(err)))
  .subscribe(markets => selectedMarkets = markets, err => console.log('getSelectedMarket', err));
  return selectedMarkets;
}
PostCashOut(marketCashout: MarketCashout): Observable<any> {

  this.PostCashOutRequest(marketCashout);
 return this._placedBetCashoutSubject.asObservable();
}

PostCashOutRequest(marketCashout: MarketCashout): any {
  const cashoutPostrequest = fromMarketRate.mapPostCashout(marketCashout);
  this.httpClient
    .post(this.getBaseUrl(cashoutPostrequest), cashoutPostrequest.body,{params:cashoutPostrequest.queryParameter})
    .pipe(catchError(err => throwError(err)))
    .subscribe((res) => this._placedBetCashoutSubject.next(res), err => console.log('PostCashOut', err));
}
getClientLimitRequest(){
  const ClientLimiRequest = fromMarketRate.mapGetClientLimitRequest();
  return this.httpClient.post(this.getBaseUrl(ClientLimiRequest),ClientLimiRequest.body)
    .pipe(catchError(err => throwError(err)))
    .subscribe((data: any) => this.clientLimit = data, err => console.log('getClientLimitRequest', err));
}
getBaseUrl(item: any): string {
  let baseUrl: string;
  if (item.baseUrl) {
    baseUrl = `${item.baseUrl}${item.endPoint}`;
  } else {
    baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
  }
  return baseUrl;
}
private init() {

  const centralMarketConnection$ = this.sessionService.centralHubConnection;

  this.sessionService.centralHubConnection.subscribe((connection) => {
    connection.on('DSRate', (markets: any) => {
      this._runningDashboardMarketSubject.next(markets);
    });
    connection.on('Rate', (markets: any) => {
      this._runningMarketSubject.next(markets);
    });
    // disconnectCommentary, getCommentary
    connection.on('connectCommentary', (commentary: any) => {
      this._marketCommentarySubject.next(commentary);
    });
  });

  const marketConnection$ = this.sessionService.marketHubConnection;
  // This trigger has used to  get event score
   marketConnection$.subscribe((connection) => {
      connection.on('getScore', (id: any, score: any) => {
        this._centralScoreSubject.next(score);
      });
    });
 // This trigger has used to change market bet allowed flag
  marketConnection$.subscribe((connection) => {
    connection.on('MarketBetAllowChanges', (data: any) => {
      this._marketBetAllowChanges.next(data);
    });
  });
  // This trigger has used to change market client limit setting
  marketConnection$.subscribe((connection) => {
    connection.on('ClientLimitChanges', (data: any) => {
      if (data.appFancyType === FanceType.Session || data.appFancyType === FanceType.AdvanceSession
        || data.appFancyType === FanceType.LineMarket) {
        if (data.appClientMaxStack !== null && data.appClientMaxStack !== undefined) {
          this.clientLimit.appSessionMaxStack = data.appClientMaxStack;
        }
        if (data.appClientMinStack !== null && data.appClientMinStack !== undefined) {
          this.clientLimit.appSessionMinStack = data.appClientMinStack;
        }
        if (data.appMaxProfit !== null && data.appMaxProfit !== undefined) {
          this.clientLimit.appSessionMaxProfit = data.appMaxProfit;
        }
      } else {
        if (data.appClientMaxStack !== null && data.appClientMaxStack !== undefined) {
          this.clientLimit.appClientBetMaxStack = data.appClientMaxStack;
        }
        if (data.appClientMinStack !== null && data.appClientMinStack !== undefined) {
          this.clientLimit.appClientBetMinStack = data.appClientMinStack;
        }
        if (data.appMaxProfit !== null && data.appMaxProfit !== undefined) {
          this.clientLimit.appMaxProfit = data.appMaxProfit;
        }
      }
      if (data.appPoint !== null && data.appPoint !== undefined) {
        this.clientLimit.appPoint = data.appPoint;
      }
      if (this.clientLimit.appIsMasterLimit === true) {
        this._clientLimitChangesSubject.next(data);
      }
    });
  });
  // This trigger has used to change market limit settings
  marketConnection$.subscribe((connection) => {
    connection.on('MarketLimitChanges', (data: any) => {
      this._marketLimitChangesSubject.next(data);
    });
  });
  // This trigger has used to update matched bet list TODO:: New
  marketConnection$.subscribe((connection) => {
    connection.on('GetBetList', (data: any) => {
      let betlistData = [], self = this;
        if (data !== null && data !== undefined) {
          if (Array.isArray(data)) {
            betlistData = data;
          } else {
            betlistData = [data];
          }
        }
        const clientBetlistData = betlistData.forEach(x => {
        if (x.appMatchID === null || x.appMatchID === undefined) {
          const runnerObj = self.curMarketsRunners.find(runner => x.appBetDetailID === runner.appBetDetailID);
            x.appMatchID = runnerObj ? runnerObj.appMatchID : null;
          }
        })
      const betSerializer = new BetSerializer();
      const betList = betSerializer.fromJson(betlistData);
      this._getBetListSubject.next(betList);
    });
  });
  // This trigger has used to delete bet list :: New
  marketConnection$.subscribe((connection) => {
    connection.on('GetDeletedBetList', (data: any) => {
      let betlistData = [], self = this;
      if (data !== null && data !== undefined) {
        if (Array.isArray(data)) {
          betlistData = data;
        } else {
          betlistData = [data];
        }
      }
      const betSerializer = new BetSerializer();
      const betList = betSerializer.fromJson(betlistData);
      this._deleteBetListSubject.next(betList);
      
    });
  });
  // This trigger has used to update matched bet list
  marketConnection$.subscribe((connection) => {
    connection.on('MatchedBetList', (data: any) => {
        this.getBetInfo();
    });
  });
  // This trigger has used to change market volume amount
  marketConnection$.subscribe((connection) => {
    connection.on('MarketRateVolumeChanges', (data: any) => {
      this._marketRateVolumeChanges.next(data);
    });
  });
  // this trigger has used to change market acticve and in-active
  marketConnection$.subscribe((connection) => {
    connection.on('MarketChanges', (data: any) => {
      if (data && data.appIsActive !== undefined && !data.appIsActive) {
        this.marketFacadeService.removeMaket(data.appBetID);
      }
    });
  });
 // This trigger has used to change market In-Play flag
 marketConnection$.subscribe((connection) => {
  connection.on('InPlayChanges', (data: any) => {
    this._inPlayChanges.next(data);
  });
 });
 // This trigger has used to change market market status
 marketConnection$.subscribe((connection) => {
  connection.on('MarketStatusChanges', (data: any) => {
    this._marketStatusChanges.next(data);
  });
 });
 // This trigger has used to chnage following things
 // 1.is show video flag
 // 2.is Commentary on
 // 3.is channel no.
 // 4.id of Commentary
 marketConnection$.subscribe((connection) => {
  connection.on('MultiMatchWiseInfo', (data: any) => {
    this._multiMatchWiseInfo.next(data);
  });
 });
 marketConnection$.subscribe((connection) => {
  connection.on('MatchClientMarketList', (data: any) => {
  //   if (data !== null) {
  //     if (data !== undefined) {
  //       this._adhocMatchSubject.next(data);
  //     }
  // }
  });
 });
 marketConnection$.subscribe((connection) => {
  connection.on('AddNewMarketData', (data: any) => {
    if (data !== null && data !== undefined) {
      const tolowercasrResp: any = this.toLowercaseObjectKeys(data);
      this.adhocMarketSearializeData(_.cloneDeep(tolowercasrResp.marketinfo[0]));
      const resp = this.addMarketSearializeData(tolowercasrResp);
      this._addNewMarketSubject.next(resp);
    }
  });
 });
  // This trigger has used to update market news
 marketConnection$.subscribe((connection) => {
   connection.on('MarketNews', (data: any) => {
     if (data !== null && data !== undefined) {
       this._marketNewsChangesSubject.next(data);
     }
  });
 });
 // This trigger has used to logout client from a website
 marketConnection$.subscribe((connection) => {
  connection.on('IsLogin', (data: any) => {
    this.checkCurrentLogin();
  });
 });
 marketConnection$.subscribe((connection) => {
  connection.on('LogOut', (data: any) => {
    this.checkCurrentLogin();
  });
 });
  }
  adhocMarketSearializeData(data) {
    if (data !== null) {
      if (data !== undefined) {
        this._adhocMatchSubject.next(data);
      }
    }
  }
  addMarketSearializeData(data) { 
    const mapResponse: {matchInfo: Match[], notificationInfo: MarketRates[], marketInfo: MarketRates[], runnerInfo: MarketRunner[] }
    = { matchInfo: [], notificationInfo: [], marketInfo: [], runnerInfo: [] };
    const matchInfo: any[] = data.matchinfo;
    // const notificationInfo: any[] = data.NotificationInfo;
    const marketInfo: any[] = data.marketinfo;
    const runnerInfo: any[] = data.runnerinfo;

  //   mapResponse.notificationInfo = notificationInfo.map(res => {
  //     if (res.appIsBetAllow == null || this.clientLimit.appIsMasterLimit) {
  //       res.appIsBetAllow = this.clientLimit.appIsBetAllow;
  //     }
  //     if (res.appClientMaxStack !== null && this.clientLimit.appIsMasterLimit === false) {
  //       res.appClientMaxStack = res.appClientMaxStack / this.clientLimit.appPoint;
  //     } else {
  //       if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
  //         res.appClientMaxStack = this.clientLimit.appSessionMaxStack;
  //       } else {
  //         res.appClientMaxStack = this.clientLimit.appClientBetMaxStack;
  //       }
  //     }
  //     if (res.appClientMinStack !== null && this.clientLimit.appIsMasterLimit === false) {
  //       res.appClientMinStack = res.appClientMinStack / this.clientLimit.appPoint;
  //     } else {
  //       if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
  //         res.appClientMinStack = this.clientLimit.appSessionMinStack;
  //       } else {
  //         res.appClientMinStack = this.clientLimit.appClientBetMinStack;
  //       }
  //     }
  //     if (res.appMaxProfit !== null && this.clientLimit.appIsMasterLimit === false) {
  //       res.appMaxProfit = res.appMaxProfit / this.clientLimit.appPoint;
  //     } else {
  //       if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
  //         res.appMaxProfit = this.clientLimit.appSessionMaxProfit;
  //       } else {
  //         res.appMaxProfit = this.clientLimit.appMaxProfit;
  //       }
  //     }
  //    return res;
  // });
    mapResponse.marketInfo = marketInfo.map(res => {
      if (res.appIsBetAllow !== null && res.appIsBetAllow !== undefined && this.clientLimit.appIsMasterLimit === false) {
        res.appIsBetAllow = res.appIsBetAllow;
      } else {  
        res.appIsBetAllow = this.clientLimit.appIsBetAllow;
      }
      if (res.appClientMaxStack !== null && this.clientLimit.appIsMasterLimit === false) {
        res.appClientMaxStack =	res.appClientMaxStack / this.clientLimit.appPoint;
      } else {
        if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
          res.appClientMaxStack = this.clientLimit.appSessionMaxStack;
        } else {
          res.appClientMaxStack = this.clientLimit.appClientBetMaxStack;
        }
      }
      if (res.appClientMinStack !== null && this.clientLimit.appIsMasterLimit === false) {
        res.appClientMinStack =	res.appClientMinStack / this.clientLimit.appPoint;
      } else {
        if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
          res.appClientMinStack = this.clientLimit.appSessionMinStack;
        } else {
          res.appClientMinStack = this.clientLimit.appClientBetMinStack;
        }
      }
      if (res.appMaxProfit !== null && this.clientLimit.appIsMasterLimit === false) {
        res.appMaxProfit =	res.appMaxProfit / this.clientLimit.appPoint;
      } else {
        if (res.appFancyType === FanceType.Session || res.appFancyType === FanceType.AdvanceSession || res.appFancyType === FanceType.LineMarket) {
          res.appMaxProfit = this.clientLimit.appSessionMaxProfit;
        } else {
          res.appMaxProfit = this.clientLimit.appMaxProfit;
        }
      }
     return res;
   });
    mapResponse.runnerInfo = runnerInfo.map(res => {
     return res;
   });

   return mapResponse;
  }
  toLowercaseObjectKeys(myObj) {
    return Object.keys(myObj).reduce((prev, current) => 
    ({ ...prev, [current.toLowerCase()]: myObj[current]}), {})
  }
}


