import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceInfoService } from '../device-info/deviceinfo.services';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';
import { websiteSettings } from '../authentication/authentication-facade.service';

@Injectable({
    providedIn: 'root'
})
export class CasinoService {
    casinoUrl = '';
    baseUrl = '';
    platform = 'desktop';
    logoUrl: any;
    lobby = 'true';
    reloadURL = '';


    constructor(private http: HttpClient, private toastr: ToastrService, public deviceInfoService: DeviceInfoService,
        private commonService: CommonService, private router: Router) {
        if (apiEndPointData.data.isHTTPS) {
            this.casinoUrl = `${apiEndPointData.data.webProdMarketApiUrl}` + 'api/Account/GetLiveCasinoToken';
            this.baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}` + 'api/Account';
        } else {
            this.casinoUrl = `${apiEndPointData.data.webProdMarketApiUrl}` + 'api/Account/GetLiveCasinoToken';
            this.baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}` + 'api/Account';
        }
        if (!this.deviceInfoService.isMobile()) {
            this.platform = 'desktop';
            this.logoUrl = '&logo_desktop=' + websiteSettings.data.BoongoLogoUrl;
        } else {
            this.platform = 'mobile';
            this.logoUrl = '&logo_mobile=' + websiteSettings.data.BoongoLogoUrl;
        }
    }
    getToken(): Observable<any> {
        return this.http.post(this.casinoUrl, {});
    }
    getCasinoToken(params) {
        const requestBody = { 'strGameCode': params.gameCode, 'tpGameId': params.types, 'tpGameTableId': params.gameCode };
        this.http.post(this.baseUrl + params.apiEndPoint, requestBody).pipe(catchError(err => throwError(err)))
            .subscribe((response: any) => {
                this.commonService.setLoadingStatus(false);
                if (response !== '' && response !== null && response !== undefined) {
                    if (response.data === '' || response.data === null) {
                        this.toastr.error(response.errorMessage);
                    } else {
                        if (response.data === null && response.status !== null) {
                            if (response.status.code !== '0') {
                                this.toastr.error(response.status.returnMessage);
                            }
                        }
                        if (response.data === undefined || response.data === null) {
                            this.toastr.error(response.errorMessage);
                        } else {
                            let lobby = response.data.lobbyURL;
                            if (params.types === 'casino') {
                                const LiveCasinoUrl = websiteSettings.data.appLivecasinoapiUrl;
                                const token = response.data['token'];
                                lobby = LiveCasinoUrl + '/signin/viewer/walletGameLogin.jsp?token=' + token +
                                    '&lobby=' + this.lobby + '&reloadURL=' + this.reloadURL;
                            }
                            if (lobby) {
                                if (websiteSettings.data.isOpenGameIframe) {
                                    localStorage.setItem('lobbyUrl', lobby);
                                    if (params.gameCode !== null && params.gameCode !== undefined) {
                                        this.router.navigate(['casinoGame', (params.gameCode ? params.gameCode : 'all')]);
                                    } else if(params.types !== null && params.types !== undefined) {
                                        this.router.navigate(['casinoGame', params.types]);
                                    } else {
                                        const gameName = params.name.replace(/ /g, '');
                                        const types = gameName !== null && gameName !== undefined && gameName !== '' ? gameName : 'liveCasino';
                                        this.router.navigate(['casinoGame', types]);
                                    }
                                } else if (websiteSettings.data.openGameInNewTab) {
                                    window.open(lobby, '_blank');
                                } else {
                                    window.location.href = lobby;
                                }
                            } else {
                                if(response.data && response.data.errorMessage) {
                                    this.toastr.error(response.data.errorMessage);
                                } else {
                                    this.toastr.error(response.errorMessage);
                                }
                            }
                        }
                    }
                }
            }, err => {
                this.commonService.setLoadingStatus(false);
                console.log('getCasinoToken', err);
            });
    }
}
