
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class ChangePasswordRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}

export function mapChangePasswordRequest(payload: any): BaseRequest<any> {
    const body = {
        oldPassword: payload.oldPassword,
        password: payload.password,
        confirmPassword: payload.confirmPassword
    };
    const request = new ChangePasswordRequest(body, {}, mapSavePasswordEndpoint(''));
    return request;
}

export function mapSavePasswordEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Account/ManagePassword';
    return endPointUrl;
}

@Injectable()
export class ChangePaswordFacadeService {
    constructor(private httpClient: HttpClient) { }

    savePassword$(payload: any): Observable<any> {
        const changePasswordRequest = mapChangePasswordRequest(payload);
        return this.httpClient
            .post(this.getBaseUrl(changePasswordRequest), changePasswordRequest.body, { params: changePasswordRequest.queryParameter })
            .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
    }
}


