import {createAction, props } from '@ngrx/store';
import { Market } from '@clientApp-core/models/market/market.model';

const prefix = `[Selected.Market]`;

export const UpsertSelectedMarket = createAction(`${prefix} Upsert Selected Market Data`, props<{market: Market}>());
export const RemoveSelectedMarket = createAction(`${prefix} Remove Selected Market Data`, props<{betId: number}>());
export const RemoveAllSelectedMarket = createAction(`${prefix} Remove All Selected Market Data`);



