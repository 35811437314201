
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';
import { guid } from '@clientApp-core/utilities/app-util';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class AccountStatementRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}

export function mapAccountStatementRequest(): BaseRequest<any> {
    const request = new AccountStatementRequest({}, {}, mapAccountStatementEndpoint(''));
    return request;
}

export function mapBetHistoryRequest(betId): BaseRequest<any> {
    const request = new AccountStatementRequest({ "betId": betId },{}, mapBetHistoryEndpoint(''));
    return request;
}

export function mapAccountStatementEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Account/Statement';
    return endPointUrl;
}

export function mapBetHistoryEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Bet/History';
    return endPointUrl;
}

@Injectable()
export class AccountStatementFacadeService {
    constructor(private httpClient: HttpClient) { }

    getAccountStatement$(): Observable<any> {
        const accountStatementRequest = mapAccountStatementRequest();
        return this.httpClient
            .post(this.getBaseUrl(accountStatementRequest),accountStatementRequest.body,{params: accountStatementRequest.queryParameter})
            .pipe(map((data: any) => { return this.accountStatementSerializer(data.result) }), catchError(err => { return throwError(err) }));
    }

    getBetHistory$(betId): Observable<any> {
        const betHistoryRequest = mapBetHistoryRequest(betId);
        return this.httpClient
            .post(this.getBaseUrl(betHistoryRequest),betHistoryRequest.body,{params: betHistoryRequest.queryParameter})
            .pipe(map((data: any) => { return this.betHistorySerializer(data) }), catchError(err => { return throwError(err) }));
    }

    accountStatementSerializer(data) {
        data.appDate = data.appDate;
        data.appCR = '-';
        data.appDR = '-';
        return Object.assign(data, { appFinalTransactionID: guid() });

    }
    betHistorySerializer(data) {
        return data.map(obj => {
            obj.appCreatedDate = obj.appCreatedDate;
            obj.appMatchedDate = obj.appMatchedDate;
            obj.appCR = '-';
            obj.appDR = '-';
            return obj;
        });
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
    }
}


