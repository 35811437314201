import { Authenticate } from '@clientApp-core/models/auth/authentication.models';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class LoginRequest implements BaseRequest<Authenticate> {
    constructor(public body: any, public queryParameter: Authenticate, public endPoint: string, public baseUrl: string = null) {}
}
export class ConfigRequest implements BaseRequest<any> {
  constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
}
export function mapLoginRequest(name: string, password: string, Captcha: string, RequestId: string): BaseRequest<Authenticate> {
  const auth = new Authenticate(name, password, Captcha, RequestId);
  const request = new LoginRequest({}, auth , mapEndpoint('Login'));
  return request;
}
export function mapConfigRequest(): BaseRequest<any> {
   const request = new ConfigRequest({}, {}, mapConfigEndpoint(''));
   return request;
}
export function mapCasinoConfigRequest(): BaseRequest<any> {
  const request = new ConfigRequest({}, {}, mapCasinoConfigEndpoint(''));
  return request;
}
 export function mapIsAuthorizedRequest(): BaseRequest<any> {
      const request = new LoginRequest({}, null, mapEndpoint('IsAuthorized'));
   return request;
}
export function mapLogoutRequest(): BaseRequest<any> {
  const request = new LoginRequest({}, null, mapLogoutEndpoint(''));
  return request;
}
export function CaptchaCodeRequest(): BaseRequest<any> {
  const request = new LoginRequest({}, null, CaptchaCodeEndPoint(''));
  return request;
}

export function mapEndpoint(endPoint: string): string {
  let endPointUrl = '';
  switch (endPoint) {
  case 'Login':
  {
    endPointUrl = 'api/Account/Login';
    break;
  }
  case 'IsAuthorized':
  {
    endPointUrl = 'api/Account/IsAuthorized';
  }
  }

  return endPointUrl;
}
export function mapConfigEndpoint(endPoint: any): string {
  const endPointUrl = 'api/Account/GetConfig';
  return endPointUrl;
}
export function mapCasinoConfigEndpoint(endPoint: any): string {
  const endPointUrl = 'api/Account/getCasinoConfig';
  return endPointUrl;
}
export function mapLogoutEndpoint(endPoint: any): string {
  const endPointUrl = 'api/Account/Logout';
  return endPointUrl;
}
export function CaptchaCodeEndPoint(endPoint: any): string {
  const endPointUrl = 'api/Account/GetCaptcha';
  return endPointUrl;
}
