import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare var _: any;

@Injectable()
export class ConfigService {
  configurations: any;
  baseurl: any;
  jsonData: any;
  constructor(private http: HttpClient) {
  }
  loadAppConfig() {
    let promise = new Promise<void>((resolve, reject) => {
      this.http.get('assets/json/config.json').subscribe((data: any) => {
        this.jsonData = data;
        this.http.post(data.getInitDataUrl + '/api/Account/GetInitData',{})
          .toPromise()
          .then((response: any) => { // Success
            apiEndPointData.data = this.addOrupdateObject(response.result);
            resolve();
          }).catch(err => {
            apiEndPointData.data = data;
            resolve();
            console.log('Configuration Failed');
          });
      });
    });
    return promise;
  }
  apiBaseUrl() {
    if (!this.configurations) {
      throw Error('Config file not loaded!');
    }
    return this.configurations;
  }
  addOrupdateObject(data: any) {
    // let object = {};
    // const bitSetting = _.forEach(data.bitSettings, function (value) {
    //   object[value.appkey] = value.appvalue;
    // });
    // const stringSettings = _.forEach(data.stringSettings, function (value) {
    //   object[value.appkey] = value.appvalue;
    // });
    // object['casinoGamesMenu'] = data.casinoMenu;
    // object['casinoGameCategory'] = data.casinoGameCategory; 
    const mergeData = _.merge(data[0], this.jsonData);
    return mergeData;
  }

}
export const apiEndPointData: any = {
  data: ''
};
