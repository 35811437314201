import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AudioType } from '@clientApp-core/enums/audio.types';

@Component({
  selector: 'pb-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit {

  @ViewChild('alertAudio', { static: true }) alertPlayerRef: ElementRef;
  @ViewChild('placebetAudio', { static: true }) placebetRef: ElementRef;
  @ViewChild('errorAudio', { static: true }) errorPlayerRef: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  playAudio(type: AudioType) {
    if (type === AudioType.alert) {
      this.alertPlayerRef.nativeElement.play();
    } else if (type === AudioType.placeBet) {
      this.placebetRef.nativeElement.play();
    } else {
      this.errorPlayerRef.nativeElement.play();
    }
  }

}
