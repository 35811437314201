<header>
  <div class="navbar-fixed">
    <ul id="dropdown1" class="dropdown-content">
      <li><a [routerLink]="['/account-statement']"><i class="fa fa-calculator"></i> Account Statement</a></li>
      <li><a [routerLink]="['/profit-and-loss']"><i class="fa fa-file-text"></i> Profit &amp; Loss</a></li>
      <li><a [routerLink]="['/chip-settings']"><i class="fa fa-gears"></i> Chip Setting</a></li>
      <li><a [routerLink]="['/change-password']"><i class="fa fa-key"></i> Change Password</a>
      </li>
      <li><a [routerLink]="['/results']"><i class="fa fa-trophy"></i> Results</a></li>
      <li><a (click)="onTcClick()"><i class="fa fa-info"></i> Terms &amp; Conditions</a></li>
      <li class="log-out"><a (click)="onLogOut()"><i class="fa fa-sign-out"></i> Logout</a></li>
    </ul>
    <nav>
      <div class="nav-wrapper valign-wrapper">
        <a href="#" data-target="slide-out" class="sidenav-trigger sidenav-close"><i class="fa fa-bars"></i></a>
        <a href="javascript:void(0);" (click)="onLogoClick()" class="brand-logo hide-on-med-and-down"><img [src]="logoUrl" alt="7SkyExch"
            class="responsive-img" /></a>
        <a href="javascript:void(0);" (click)="onLogoClick()" class="brand-logo hide-on-large-only"><img [src]="logoUrl" alt="7SkyExch"
            class="responsive-img" /></a>
        <marquee behavior="scroll" class="marquee-style hide-on-med-and-down" direction="left"
          onmouseout="this.start();" onmouseover="this.stop();" scrolldelay="100">
          <div class="marquee-news"  *ngFor="let value of news">
            <i class="fa fa-circle"></i>
            <span class="marquee-news-text">{{value}}</span>
          </div>
        </marquee>
        <ul class="right user-menus">
          <li><a href="javascript:void(0);" *ngIf="isShowBalance"><i class="fa fa-credit-card"></i> <span>Balance:</span>
              <span>{{ !account?.balance ? '0' :account?.balance}}</span></a></li>
          <li><a href="javascript:void(0);" (click)="onLiabilityClick()"><i
                class="fa fa-database"></i> <span>Liability:</span>
              <span>{{ !account?.liability ? '0' : account?.liability }}</span></a></li>
        </ul>
      </div>
    </nav>
    <nav class="header-bottom">
      <div class="nav-wrapper valign-wrapper">
        <div class="row margin-0">
          <div class="col s12 m9 p0">
            <div class="tabMenu">
              <ul class="menu">
                <li><a  href="javascript:void(0);" (click)="onLogoClick()" [class.active]="selectedSport === 'Home'">Home</a></li>
                <li><a  href="javascript:void(0);" (click)="onInPlayClick()" [class.active]="selectedSport === 'InPlay'">In-Play</a></li>
                <li><a  href="javascript:void(0);" (click)="onMultiMarketsClick()" [class.active]="selectedSport === 'MultiPin'">Multi Markets</a></li>
                <li *ngFor="let market of markets;" ><a  href="javascript:void(0);" 
                  (click)="onSportsClick(market.sport)" [class.active]="selectedSport === market.sport">
                  <span id="tagLive" class="tag-live" *ngIf="getSportwiseInplayCount(market.sport); let inPlayCount"><strong></strong>{{inPlayCount}}</span> {{market.sport}}
                  <!-- <span id="tagLive" class="tag-live"><strong></strong>3</span> -->
                  </a></li>
              <ng-container *ngFor="let item of excludeSports;">
                <li *ngIf="isActiveExcludeSports(item.name)">
                  <a href="javascript:void(0)" [class.active]="selectedSport === item.name" (click)="excludeSportsClick(item.name)">
                    {{item.name}}
                  </a>
                </li>
              </ng-container>
              </ul>
            </div>
          </div>
          <div class="col s12 m3 p0">
            <div class="news-scroll hide-on-large-only hide">
              <span class="hide-on-med-and-down">Betting News : </span>
              <marquee behavior="scroll" direction="left">
                <ng-container *ngFor="let value of news">
                  <i _ngcontent-c5="" class="fa fa-circle"></i>&nbsp;{{value}}&nbsp;&nbsp;&nbsp;&nbsp;
                </ng-container>
              </marquee>
            </div>
            <ul class="right user-menus">
              <li *ngIf="isShowOneClickBet">
                <a style="display: inline-flex;text-decoration: none;">
                <label>
                  <input type="checkbox" class="filled-in" [(ngModel)]="isCheckedOneClickBet"
                  (ngModelChange)="onChange($event)" />
                  <span class="one-click">One Click Bet</span>
                </label>
                <span class="one-click-value" *ngIf="isCheckedOneClickBet">({{selectedStakeValue}}) -<span class="one-click-edit-btn" (click)="onOneClick()">Edit</span></span>
              </a>
              </li>
              <li>
                <a class="dropdown-trigger" data-target="dropdown1">
                  <span>Hi, {{user.clientName}}</span> <i class="fa fa-caret-down right"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <pb-left-sidebar [allMatches]="allMarkets" [markets]="markets" [config]="config" ></pb-left-sidebar>
</header>
<div class="news-feed hide-on-med-and-up show-on-medium-and-down" *ngIf="news && news.length > 0">
  <marquee behavior="scroll" class="m-marquee-style" direction="left" onmouseout="this.start();"
    onmouseover="this.stop();" scrolldelay="100">
    <div class="marquee-news" *ngFor="let value of news">
      <i class="fa fa-circle"></i>
      <span class="marquee-news-text">{{value}}</span>
    </div>
    <div style="clear: both;"></div>
  </marquee>
</div>
<pb-rules #rules [name]="'Terms and Condition'"></pb-rules>
<pb-liability #liability></pb-liability>
<app-one-click #oneclick></app-one-click>