
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class ProfitAndLossRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) { }
}
export function mapProfitLossDetailsRequest(betId): BaseRequest<any> {
    const request = new ProfitAndLossRequest({ "betId": betId },{}, mapProfitLossDetailsEndpoint(''));
    return request;
}
export function mapProfitAndLossRequest(payload): BaseRequest<any> {
    const request = new ProfitAndLossRequest(payload, {}, mapEndpoint(''));
    return request;
}
export function mapEndpoint(endPoint: any): string {
    const endPointUrl = 'api/ProfitLoss';
    return endPointUrl;
}
export function mapProfitLossDetailsEndpoint(endPoint: any): string {
    const endPointUrl = 'api/ProfitLoss/Detail/Information';
    return endPointUrl;
}

@Injectable()
export class ProfitAndLossFacadeService {
    constructor(private httpClient: HttpClient) { }

    getProfitAndLoss$(payload): Observable<any> {
        const profitAndLossRequest = mapProfitAndLossRequest(payload);
        return this.httpClient
            .post(this.getBaseUrl(profitAndLossRequest), profitAndLossRequest.body)
            .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getProfitLossDetails$(betId): Observable<any> {
        const profitLossDetailsRequest = mapProfitLossDetailsRequest(betId);
        return this.httpClient
            .post(this.getBaseUrl(profitLossDetailsRequest),profitLossDetailsRequest.body, { params: profitLossDetailsRequest.queryParameter })
            .pipe(map((data: any) => { return data }), catchError(err => { return throwError(err) }));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
    }
}


