import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { apiEndPointData } from '@clientApp-core/services/config/connfig.service';
import * as M from "materialize-css/dist/js/materialize";
import { ToastrService } from 'ngx-toastr';
declare var _: any;

@Component({
  selector: 'app-one-click',
  templateUrl: './one-click.component.html',
  styleUrls: ['./one-click.component.scss']
})
export class OneClickComponent implements OnInit, AfterViewInit,  OnDestroy {
  oneClickInstances: any;
  oneClickStakeData = [];
  selectedIndex = 0;
  stakeSettingForm: FormGroup;
  isEditStakeValue: boolean = false;
  @ViewChild('oneclick', { static: true }) template: ElementRef;
  @ViewChildren('input') inputs: QueryList<ElementRef>;
  constructor(private formBuilder: FormBuilder,private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.init();
  }
  ngAfterViewInit() {
    this.oneClickInstances = M.Modal.init(this.template.nativeElement, { dismissible: false });
  }
  setChipValue(stakeValue: string, index: number) {
    this.selectedIndex = index;
    this.commonService.setCookieValue('oneClickSelectedStake', JSON.stringify(stakeValue));
    this.toastr.success(`Chip set Successfully`);
    this.commonService.setOneClickStakeStatus(stakeValue);
  }
  init() {
    const oneClickStakeValuesData = this.commonService.getCookieValue('oneClickStakeValuesData');
    if (oneClickStakeValuesData != null && oneClickStakeValuesData != undefined && oneClickStakeValuesData != '' && JSON.parse(oneClickStakeValuesData)) {
      this.oneClickStakeData = JSON.parse(oneClickStakeValuesData);
    } else {
      this.oneClickStakeData = apiEndPointData.data.oneClickStakeValue;
    }
    const oneClickSelectedStake = this.commonService.getCookieValue('oneClickSelectedStake');
    if (oneClickSelectedStake != null && oneClickSelectedStake != undefined && oneClickSelectedStake != '' && JSON.parse(oneClickSelectedStake)) {
      const ind = this.oneClickStakeData.findIndex(x => x === JSON.parse(oneClickSelectedStake));
      this.selectedIndex = ind > -1 ? ind : 0;
    } else {
      this.selectedIndex = 0;
      this.commonService.setCookieValue('oneClickSelectedStake', JSON.stringify(this.oneClickStakeData[0]));
      this.commonService.setOneClickStakeStatus(this.oneClickStakeData[0]);
    }
    this.stakeSettingForm = this.formBuilder.group({
      items: this.formBuilder.array(this.oneClickStakeData.map(chips => this.createItem(chips)))
    });
  }
  createItem(chip: any): FormGroup {
    return this.formBuilder.group({
      chipValue: [chip],
    });
  }
  onSubmit() {
    if (this.isEditStakeValue) {
      const updatedChips = [], self = this;
      const currentSelectedStakeVal = _.cloneDeep(self.oneClickStakeData[this.selectedIndex]);
      this.stakeSettingForm.get('items')['controls'].forEach((control , index) => {
        // tslint:disable-next-line:max-line-length
        if (control.value.chipValue !== null && control.value.chipValue !== undefined) {
          const value = +control.value.chipValue
          updatedChips.push(value.toString());
        } else {
          const value = self.oneClickStakeData[index];
          updatedChips.push(value);
        }
      });
      this.oneClickStakeData = _.cloneDeep(updatedChips);
      this.commonService.setCookieValue('oneClickStakeValuesData', JSON.stringify(updatedChips));
      this.toastr.success(`Chip set Successfully`);
      const updatedSelectedStakeVal = _.cloneDeep(self.oneClickStakeData[this.selectedIndex]);
      if (currentSelectedStakeVal !== updatedSelectedStakeVal) {
        this.commonService.setCookieValue('oneClickSelectedStake', JSON.stringify(updatedSelectedStakeVal));
        this.commonService.setOneClickStakeStatus(updatedSelectedStakeVal);
      }
    } else {
      setTimeout(() => {
        this.inputs.first.nativeElement.focus();
      },50)
    }
    this.isEditStakeValue = !this.isEditStakeValue;
  }

  onReset() {
    this.stakeSettingForm = this.formBuilder.group({
      items: this.formBuilder.array(this.oneClickStakeData.map(chips => this.createItem(chips)))
    });
  }
  openPopup() {
    this.init();
    this.isEditStakeValue = false;
    this.oneClickInstances.open();
  }
  hideModal() {
    this.isEditStakeValue = false;
    this.oneClickInstances.close();
  }
  ngOnDestroy() {}
}
