
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiEndPointData } from '../config/connfig.service';
import { HttpClient } from '@angular/common/http';
import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class TCRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
 }

 export function mapTCRequest(): BaseRequest<any> {
    const request = new TCRequest({}, {}, mapEndpoint(''));
    return request;
}

export function mapEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Account/TermsCondition';
    return endPointUrl;
}


@Injectable()
export class TCFacadeService {
    constructor(private httpClient: HttpClient) {}

    getTC$(): Observable<any> {
       const tcRequest = mapTCRequest();
        return this.httpClient.post(this.getBaseUrl(tcRequest), tcRequest.body)
        .pipe(map((data: any) => { return data.result }), catchError(err => { return throwError(err) }));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
          baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
          baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
      }
}


