  <!-- Rules -->
  <div id="rules" class="modal modal-fixed-footer" #rules>
    <div class="modal-header">
      <h5>{{name ? name : 'Rules'}}</h5>
      <span class="modal-close" (click)="hideModal()"><i class="small material-icons">cancel</i></span>
    </div>
    <div class="modal-content">
      <!-- <h5>{{name ? name : 'Rules'}}</h5>
      <span class="modal-btn-close" (click)="hideModal()"><i class="small material-icons">cancel</i></span> -->
      <div class="modal-body"  [innerHTML]="marketRules"></div>
    </div>
    <div class="modal-footer">
      <a href="javascript:void(0)" (click)="hideModal()" class="modal-close waves-light red btn">Close</a>
    </div>
  </div> 