
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@clientApp-core/interceptors/authHttpInterceptor';

import { HomeResolve } from '@clientApp-core/resolvers/home.resolve';
import { TCFacadeService } from '@clientApp-core/services/tc/tc.facade.service';

import { AccountStatementFacadeService } from '@clientApp-core/services/account-statement/account-statement-facade.service';
import { MarketService } from '@clientApp-core/services/market/market.service';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { SharedService } from '@clientApp-core/services/shared/shared.service';

import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { LoggedInUserGuard } from '@clientApp-core/gaurds/logged-in-user.guard';
import { StoreService } from '@clientApp-core/services/store/store.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { NewsFacadeService } from '@clientApp-core/services/news/news-facade.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { MarketRateService } from '@clientApp-core/services/market/market-rates/market-rate.service';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';
import { ChipFacadeService } from '@clientApp-core/services/chip/chip-facade.service';
import { ChangePaswordFacadeService } from '@clientApp-core/services/change-password/change-password-facade.service';
import { ProfitAndLossFacadeService } from '@clientApp-core/services/profit-and-loss/profit-and-loss-facade.service';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { ResultsFacadeService } from '@clientApp-core/services/results/results.facade.service';
import { CasinoService } from '@clientApp-core/services/casino/casino.service';
import { ConfigService } from '@clientApp-core/services/config/connfig.service';
import { CricketLiveScoreService } from './services/LiveScore/cricket-live-score.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AuthFacadeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    SessionService,
    SharedService,
    TCFacadeService,
    NewsFacadeService,
    MarketService,
    MarketFacadeService,
    AccountStatementFacadeService,
    MarketRateService,
    MarketRateFacadeService,
    ChipFacadeService,
    BetFacadeService,
    ChangePaswordFacadeService,
    CommonService,
    ProfitAndLossFacadeService,
    StoreService,
    DeviceInfoService,
    ResultsFacadeService,
    CasinoService,
    ConfigService,
    CricketLiveScoreService,

    /** Resolvers */
     HomeResolve,

    /** Gaurds */
   LoggedInUserGuard,
  ]
})
export class CoreModule { }
