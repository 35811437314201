import { BaseRequest } from '@clientApp-core/models/base/base-request.model';

export class MarketRequest implements BaseRequest<any> {
    constructor(public body: any, public queryParameter: any, public endPoint: string, public baseUrl: string = null) {}
 }

 export function mapMarketRequest(): BaseRequest<any> {
    const request = new MarketRequest({}, {}, mapEndpoint(''));
    return request;
}

export function mapEndpoint(endPoint: any): string {
    const endPointUrl = 'api/Market/Active';
    return endPointUrl;
}
