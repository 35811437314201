import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { HomeLayoutComponent } from './features/layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './features/layouts/login-layout/login-layout.component';
import { ConfigService } from '@clientApp-core/services/config/connfig.service';
import { CookieService } from 'ngx-cookie-service';

import { FormsModule } from '@angular/forms';
import { CoreModule } from '@clientApp-core/core.module';
import { SharedModule } from '@clientApp-shared/shared.module';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { routes } from './app.routing';
import { ParkBetStoreModule } from '@clientApp-store/store.module';
import { DatePipe } from '@angular/common';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

export function loadConfigurations(configService: ConfigService) {
  return () => configService.loadAppConfig();
}
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
    'pinch': { enable: false },
    'rotate': { enable: false },
    'pan': { enable: false }
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginLayoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    RouterModule.forRoot(routes, { useHash: true, enableTracing: false, scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ParkBetStoreModule
  ],
  providers: [CookieService, DatePipe,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: loadConfigurations,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
