<div id="oneclick" class="modal" #oneclick>
    <div class="modal-content" style="padding: 0;">
      <h4>{{isEditStakeValue ? 'Edit ': ''}}One Click Bet Stake</h4>
      <div class="row margin-0" style="width: 100%;padding: 15px;">
      <div class="col s12 one_click-setting" *ngIf="!isEditStakeValue">
        <a *ngFor="let chip of oneClickStakeData;; let i= index" href="javascript:void(0)"
        [class.select]="selectedIndex === i" (click)="setChipValue(chip,i)">{{chip}}</a>
      </div>
        <form class="col s12 one_click-setting" *ngIf="isEditStakeValue" [formGroup]="stakeSettingForm" novalidate
        (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">
        <div formArrayName="items" class="items"
        *ngFor="let item of stakeSettingForm.get('items')['controls']; let i = index;">
        <div [formGroupName]="i" class="row" style="margin:0;">
          <div *ngIf="item.get('chipValue') as chipsNameCtrl" style=" margin-bottom: 2px !important;
          margin-right: 5px !important;">
            <input #input  placeholder="Enter Stake Value" formControlName="chipValue" class="form-control" type="number">
          </div>
        </div>
      </div>
        </form>
    </div>
    </div>
    <div class="modal-footer">
      <a href="javascript:void(0)" 
      class="waves-light green btn right" (click)="onSubmit()">{{isEditStakeValue ? 'Save': 'Edit'}}</a>
      <a href="javascript:void(0)" (click)="hideModal()"
                          class="waves-light red btn right" style="margin-right: 5px;">Close</a>
    </div>
  </div>