<ng-template #casino>
  <div class="modal-header">
    <h4 class="modal-title color-Black text-center" style="width:100%;">Welcome To LiveCasino</h4>
    <button type="button" class="close" aria-label="Close"  style="margin-left:0;line-height:34px;"> <!--(click)="modalRef.hide()"-->
      <span aria-hidden="true"><i class="material-icons"> close </i></span>
    </button>
  </div>
  <div class="modal-body" [innerHtml]="IframeVideo | safe: 'html'">
  </div>
</ng-template>
