
import { Injectable } from '@angular/core';
import { Market } from '@clientApp-core/models/market/market.model';
import { mapMarketRequest } from '@clientApp-core/services/market/market-helper.service';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';


@Injectable()
export class MarketFacadeService {

    private _marketSubject = new Subject<Market[]>();
    private _removeMarketSubject = new Subject<any>();
    private _unpinMarketSubject = new Subject<any>();
    private _setVideoSubject = new Subject<any>();
    private _removeBetFromBetListSubject = new Subject<any>();
    marketList: Market[] = [];

    constructor(private httpClient: HttpClient) {}

    getMarkets$(): Observable<Market[]> {
        return this._marketSubject.asObservable();
    }
    getMarketToRemove$(): Observable<any> {
        return this._removeMarketSubject.asObservable();
    }
    removeBetFromBetList(): Observable<any> {
      return this._removeBetFromBetListSubject.asObservable();
    }
    removeMaket(id: any) {
      const obj = { BetId: id, MatchId: null };
      this._removeBetFromBetListSubject.next(obj);
        return this._removeMarketSubject.next(id);
    }
    getVideo$(): Observable<any> {
      return this._setVideoSubject.asObservable();
    }
    setVideo(id: any) {
        return this._setVideoSubject.next(id);
    }
    getMarketToUnpin$(): Observable<any> {
      return this._unpinMarketSubject.asObservable();
    }
    unpinMaket(id: any) {
      const obj = { BetId: null, MatchId: id };
      this._removeBetFromBetListSubject.next(obj);
        return this._unpinMarketSubject.next(id);
    }

    getMarkets(): void {
      const marketRequest = mapMarketRequest();
      this.httpClient
      .post(this.getBaseUrl(marketRequest), {params:marketRequest.queryParameter})
      .pipe(map((data: any) => { return data.result }),take(1), catchError(err => throwError(err)))
      .subscribe((market: Market[]) =>{  
          this.marketList = market; 
          this._marketSubject.next(market); 
        }, err => console.log('marketRequest', err));
    }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
          baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
          baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
      }
}


