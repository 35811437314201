import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { websiteSettings } from '../authentication/authentication-facade.service';

@Injectable({
  providedIn: 'root'
})
export class CricketLiveScoreService {
  liveScoreUrl = '';
  livescoreUrlEndpoint = '';

  constructor(private http: HttpClient) {
    this.liveScoreUrl = websiteSettings.data.appLiveScoreUrl;
    this.livescoreUrlEndpoint = websiteSettings.data.appLiveScoreUrlendpoint;
   }


  getLiveScores(eventId: any) {
    this.liveScoreUrl = websiteSettings.data.appLiveScoreUrl;
    this.livescoreUrlEndpoint = websiteSettings.data.appLiveScoreUrlendpoint;
    return this.http.get(this.liveScoreUrl  +
      eventId + this.livescoreUrlEndpoint);
  }
  getLiveScoreswidget(eventId: any) {
    const url = websiteSettings.data.appScoreWidgetUrl + eventId ;
    return this.http.get(url);
  }
}
