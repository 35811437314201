<ul class="sidenav" id="slide-out" #sidenav>
    <li class="topinplay">
        <p>In Play</p><span>{{inPlayCount ? inPlayCount : '-'}}</span>
    </li>
    <li class="path">
        <a href="javascript:void(0);" class="path-back">...</a>
        <ul>
            <li class="path-last">
                <a href="javascript:void(0);">Sports</a>
            </li>
        </ul>
    </li>
    <li>
        <ul class="collapsible">
            <li *ngFor="let market of markets;let i = index">
                <div class="collapsible-header" (click)="currentTournamentIndex=0"><i class="fa fa-trophy"></i>{{market.sport}} <i class="fa fa-plus"></i>
                </div>
                <div *ngIf="!isListwithTournament" class="collapsible-body">
                    <ul class="collapsible">
                        <li *ngFor="let match of market.matches; ">
                            <a (click)="onMarketClick(match)" class="sidenav-close">{{match.name}}</a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="isListwithTournament" class="collapsible-body">
                    <ul class="collapsible">
                        <li *ngFor="let tour of market.appTournament let last = last;let j = index">
                            <div class="collapsible-header"
                                (click)="currentTournamentIndex = (currentTournamentIndex > 0 ? (currentTournamentIndex==(j+1)?0:(j+1)):(j+1))">
                                <a href="javascript:void(0)" class="tourMenuCls">
                                    {{tour.tournamentName}}
                                    <i class="fa pull-right" [class.fa-plus]="currentTournamentIndex!=(j+1)"
                                        [class.fa-minus]="currentTournamentIndex==(j+1)"
                                        style="font-size: 12px !important;text-align: end;"></i>
                                </a>
                            </div>
                            <ul *ngIf="(j+1)==currentTournamentIndex" class="collapsible">
                                <li *ngFor="let match of tour.matches; ">
                                    <div class="collapsible-header">
                                        <a (click)="onMarketClick(match)" class="sidenav-close">{{match.name}}</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </li>
        </ul>
    </li>
    <ng-container *ngFor="let game of casinoGameMenu">
        <li *ngIf="config && config[game.apiParamName] && game.isActive">
            <a class="sidenav-close" [class.blink-menu]="game.isNewGame" (click)="onCasinoGameClick(game)">
                <i class="fa fa-trophy"></i>
                {{game.name}}
            </a>
        </li>
    </ng-container>
</ul>
<app-livecasino #casino></app-livecasino>
