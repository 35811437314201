import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import { UpsertSelectedMarket } from '@clientApp-store/selected-market/actions/selected-market.actions';
import { Router } from '@angular/router';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { BetFacadeService } from '@clientApp-core/services/bet/bet.facade.service';
declare var _: any;
import { StoreService } from '@clientApp-core/services/store/store.service';
import { take, catchError } from 'rxjs/operators';
import { DeviceInfoService } from '@clientApp-core/services/device-info/deviceinfo.services';
import { CommonService } from '@clientApp-core/services/common/common.service';
import * as M from "materialize-css/dist/js/materialize";
import { throwError } from 'rxjs';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';

@Component({
  selector: 'pb-liability',
  templateUrl: './liability.component.html',
  styleUrls: ['./liability.component.css']
})
export class LiabilityComponent implements OnInit {
  @ViewChild('liability', { static: true }) template: ElementRef;

  liabilityData: any[];
  totalLiability: number;
  allMarkets: any;
  modalInstances: any;
  loading = false;


  constructor(private store: Store<ParkBetState>, private router: Router, private sessionService: SessionService,
    public betService: BetFacadeService, public storeService: StoreService, private deviceInfoService: DeviceInfoService,
    private commonService: CommonService, private nodeCentralService: NodeCentralService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalInstances = M.Modal.init(this.template.nativeElement, {});
  }

  hideModal() {
    this.modalInstances.close();
  }
  openPopup() {
    this.commonService.setLoadingStatus(true);
    this.liabilityData = [];
    this.betService.getLiability();
    this.allMarkets = this.commonService.allMarkets;
    this.betService.checkLiability().pipe(take(1), catchError(err => throwError(err))).subscribe(res => {
      this.liabilityData = res;
      this.getTotalLiability();
      this.commonService.setLoadingStatus(false);
      this.modalInstances.open();
    }, err => { this.commonService.setLoadingStatus(false); console.log('getConfig', err) });
  }
  getTotalLiability() {
    this.totalLiability = _.sumBy(this.liabilityData, function (x) { return x.appLiability; });
  }
  onMarketSelect(market: any) {
    if (!this.deviceInfoService.isMobile()) {
      this.removeCentralGroup(market);
    }
    this.storeService.removeAllStoreData();
    this.store.dispatch(UpsertSelectedMarket({ market: market }));
    if (websiteSettings.data.isSocketRateEnabled) {
      this.nodeCentralService.addnewMarket(market.appcentralizationid);
    } else {
      this.sessionService.joinCentralGroup(market.appcentralizationid);
    }
    this.sessionService.addMarketGroup('', 3, market.appMatchID, market.appBetID, market.appcentralizationid, 0, 0, 0);
    this.commonService.isMarketLiabilityClick = true;
    this.router.navigateByUrl('/market');
    this.modalInstances.close();
    this.loading = false;

  }
  removeCentralGroup(matches: any) {
    if (!websiteSettings.data.isSocketRateEnabled && this.allMarkets && this.allMarkets.length > 0) {
      const centralizationIds = this.allMarkets.map(match => match.appcentralizationid);
      const SelectedcentralizationIds = matches.appcentralizationid.toString();
      for (let i = 0; i < centralizationIds.length; i++) {
        if (!SelectedcentralizationIds.includes(centralizationIds[i])) {
          this.sessionService.removeCentralGroup(centralizationIds[i].toString());
        }
      }
      // this.betService.LastShowTimeMarket(SelectedcentralizationIds);
    }
  }
  removeAllGroup() {
    if (!websiteSettings.data.isSocketRateEnabled && this.allMarkets && this.allMarkets.length > 0) {
      const centralizationIds = this.allMarkets.map(match => match.appcentralizationid).toString();
      this.sessionService.removeAllCentralGroup(centralizationIds);
    }
  }
}
