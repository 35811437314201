import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { CasinoService } from '@clientApp-core/services/casino/casino.service';
declare var $: any;
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { websiteSettings } from '@clientApp-core/services/authentication/authentication-facade.service';

@Component({
  selector: 'app-livecasino',
  templateUrl: './livecasino.component.html',
  styleUrls: ['./livecasino.component.css']
})
export class LivecasinoComponent implements OnInit, OnDestroy {
  @ViewChild('casino', { static: true }) template: TemplateRef<any>;
  config = {
    class: 'casino-modal'
  };
  user: any;
  LiveCasinoUrl: string;
  LiveCasino_URL = '';
  token: any;
  lobby = 'true';
  reloadURL = '';
  IframeVideo: any;
  configuration: any;
  constructor(
    private casinoService: CasinoService, private toastr: ToastrService,
    private commonService: CommonService, public router: Router) {
  }

  ngOnInit() {
  }
  openPopup() {
    this.casinoService.getToken()
      .pipe(catchError(err => throwError(err)))
      .subscribe(response => {
        this.commonService.setLoadingStatus(false);
        if (response.data === '' || response.data === null) {
          this.toastr.error(response.errorMessage);
        } else {
          const LiveCasinoUrl = websiteSettings.data.appLivecasinoapiUrl;
          this.token = response.data['token'];
          this.LiveCasino_URL = LiveCasinoUrl + '/signin/viewer/walletGameLogin.jsp?token=' + this.token +
            '&lobby=' + this.lobby + '&reloadURL=' + this.reloadURL;
          this.IframeVideo = '<iframe id="livecasinopopup" style="width:100%;height:100%;border:none;" src=" ' +
            this.LiveCasino_URL + '" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" marginwidth="0" marginheight="0"></iframe>';
          if ($(window).width() < 767) {
            if (websiteSettings.data.isOpenGameIframe) {
              localStorage.setItem('lobbyUrl', this.LiveCasino_URL);
              this.router.navigate(['casinoGame', 'casino']);
            } else if (websiteSettings.data.openGameInNewTab) {
              window.open(this.LiveCasino_URL, '_blank');
            } else {
              window.location.href = this.LiveCasino_URL;
            }
          } else {
            if (websiteSettings.data.isOpenGameIframe) {
              localStorage.setItem('lobbyUrl', this.LiveCasino_URL);
              this.router.navigate(['casinoGame', 'casino']);
            } else{
              // this.modalRef = this.modalService.show(this.template, this.config); TODO: open casino game in modal popup
            }
            
          }
        }
      }, err => {
        this.commonService.setLoadingStatus(false);
        console.log('getLiveCasinoToken', err);
      });
  }
  ngOnDestroy() { }
}
