import { Component, HostListener } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router, Event } from '@angular/router';
import { CommonService } from '@clientApp-core/services/common/common.service';
import { SessionService } from '@clientApp-core/services/session/session.service';
import { AuthFacadeService } from '@clientApp-core/services/authentication/authentication-facade.service';
import { MarketFacadeService } from '@clientApp-core/services/market/market-facade.service';
import { NodeCentralService } from '@clientApp-core/services/session/node-central.service';
import { take } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConnectionState } from '@clientApp-core/enums/connectionState-type';
import { MarketRateFacadeService } from '@clientApp-core/services/market/market-rates/market-rate-facade.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  loading = false;
  status = 'ONLINE';
  isConnected = true;
  constructor(private router: Router
    , private commonService: CommonService
    , private sessionService: SessionService
    , private _authService: AuthFacadeService
    , private marketRateFacadeService: MarketRateFacadeService
    , private marketFacadeService: MarketFacadeService
    , private nodeCentralService: NodeCentralService
    , private toaster: ToastrService) {
    router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        if (!router.navigated && routerEvent.url.indexOf('/login') === -1) {
          this._authService.IsAuthorized$().pipe(take(1)).subscribe((data:any) => {
            if (data.isNewToken) {
              const user = JSON.parse(localStorage.getItem('token'));
              if (user != null) {
               user.access_token = data.token;
               user.expireOn = data.expireOn;
               localStorage.setItem('token', JSON.stringify(user));
              }
            }
            if (data.isAuthorized) {
              if (routerEvent.url.indexOf('/market') !== -1) {
                localStorage.setItem('is_refreshed', JSON.stringify({ isRefreshed: true }));
                this._authService.getConfig();
              }
              this.marketFacadeService.getMarkets();
            }
          }, error => console.warn('User is unauthorized please login', error));
        }
      }
      window.scrollTo(0, 0);
      this.checkRouterEvent(routerEvent);
    });
    this.isConnected = window.navigator.onLine;
    fromEvent(window, 'online').subscribe(e => {
      this.isConnected = true;
      const user = JSON.parse(localStorage.getItem('token'));
      console.log('ONLINE');
      this.checkCurrentStatus();
      this.toaster.success('Connected');
    });
    fromEvent(window, 'offline').subscribe(e => {
      this.isConnected = false;
      console.log('OFFLINE');
      this.toaster.error('You loss your internet connection. Please check internet connection.', 'No Internet', {disableTimeOut: true});
    });
  }
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.checkCurrentStatus();
  }
  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    if (isVisible) {
      this.checkCurrentStatus();
    } else {
     // tab is not-visible
    }
  }
  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
      this.commonService.setLoadingStatus(true);
    }
    if (routerEvent instanceof NavigationEnd
       || routerEvent instanceof NavigationCancel
       || routerEvent instanceof NavigationError) {
        this.loading = false;
        this.commonService.setLoadingStatus(false);
    }
  }
  checkCurrentStatus() {
    const user = JSON.parse(localStorage.getItem('token'));
    if (this.checkCurrentStateToProcced(this.sessionService.centeralHubState,
      this.sessionService.marketHubState)) {
      if (user != null) {
        console.log('Disconnected');
        this.sessionService.startHubConnections();
      }
    }
    if (user != null && !this.nodeCentralService.socketState) {
      this.nodeCentralService.StartSocketConnection();
    }
  }
  private checkCurrentStateToProcced(marketHubState: number,  centralHubState: number): boolean {
    if (marketHubState === ConnectionState.connected && centralHubState === ConnectionState.connected) {
      return false;
    } else {
      return true;
    }
   }
}
