import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of, throwError } from 'rxjs';
import { Stake } from '@clientApp-core/models/bet/stake.model';
import { EstimatedProfitLoss } from '@clientApp-core/models/bet/estimated-profit-loss.model';
import * as fromBet from './bet.service.helper';
import { PlaceBet } from '@clientApp-core/models/bet/place-bet.model';
import { switchMap, take, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiEndPointData } from '../config/connfig.service';



@Injectable()
export class BetFacadeService {

    private _placedBetSubject = new Subject<any>();
    private _setStakeSubject = new Subject<Stake>();
    private _balanceSubject = new Subject<any>();
    private _liabilitySubject = new Subject<any>();
    private _OverlayPanelSubject = new Subject<boolean>();
    private _deleteBetSubject = new Subject<any>();
    private _estimatedProfitLossSubject = new BehaviorSubject<any>(null);
    private _isPlaceBetClicked: boolean;
    private _SelectedRuner = new Subject<string>();
    private _setBetIdSubject = new Subject<number>();
    selectedBetDetailId: number;
    lastSelectedItem: string;

    constructor(private httpClient: HttpClient) { }

    getStake$(): Observable<Stake> {
        return this._setStakeSubject.asObservable();
    }

    setStake(): Subject<Stake> {
        return this._setStakeSubject;
    }

    getEstimatedProfitLoss(): Observable<EstimatedProfitLoss[]> {
        return this._estimatedProfitLossSubject.asObservable();
    }

    sendEstimatedProfitLoss(): BehaviorSubject<EstimatedProfitLoss[]> {
        return this._estimatedProfitLossSubject;
    }

    getBetStatus(): boolean {
        return this._isPlaceBetClicked;
    }

    setBetStatus(value: boolean) {
        this._isPlaceBetClicked = value;
    }

    deleteBet(betId: number, clientId: number): Observable<any> {
        this.deleteBetRequest(betId, clientId);
        return this._deleteBetSubject.asObservable();
    }

    getOvelayStatus$(): Observable<boolean> {
        return this._OverlayPanelSubject.asObservable();
    }

    setOvelayStatus(): Subject<boolean> {
        return this._OverlayPanelSubject;
    }
    getSelectedRunner$(): Observable<string> {
        return this._SelectedRuner.asObservable();
    }

    setSelectedRunner(): Subject<string> {
        return this._SelectedRuner;
    }
    getBetId$(): Observable<number> {
        return this._setBetIdSubject.asObservable();
    }

    setBetId(): Subject<number> {
        return this._setBetIdSubject;
    }

    placeBet(placeBet: PlaceBet): Observable<any> {
        this._OverlayPanelSubject.next(true);
        this.placeBetRequest(placeBet);
        return this._placedBetSubject.asObservable();
    }

    getBalance(): void {
        const balanceRequest = fromBet.mapBalanceBetRequest();
        this.httpClient.post(this.getBaseUrl(balanceRequest), { params: balanceRequest.queryParameter }).pipe(map((data: any) => { return data.result }), switchMap((resp: any) => {
            return of({
                balance: resp.balance,
                liability: resp.liability
            });
        }), take(1), catchError(err => throwError(err)))
            .subscribe(
                value => this._balanceSubject.next(value),
                err => console.log('getBalance', err)
            );

    }

    checkBalance$(): Observable<any> {
        return this._balanceSubject.asObservable();
    }

    getLiability(): void {
        const liabilityRequest = fromBet.mapLiabilityBetRequest();
        this.httpClient.post(this.getBaseUrl(liabilityRequest), {params:liabilityRequest.queryParameter})
            .pipe(map((data: any) => { return data.result }),take(1), catchError(err => throwError(err)))
            .subscribe(
                (response: any) => this._liabilitySubject.next(response),
                err => console.log('getLiability', err)
            );
    }

    checkLiability(): Observable<any> {
        return this._liabilitySubject.asObservable();
    }

    private placeBetRequest(placeBet: PlaceBet) {
        const placeBetRequest = fromBet.mapPlaceBetRequest(placeBet);
        this.httpClient.post(this.getBaseUrl(placeBetRequest), placeBetRequest.body, {params: placeBetRequest.queryParameter})
            .pipe(take(1), catchError(err => throwError(err)))
            .subscribe(
                (response: any) => this._placedBetSubject.next(response),
                err => this._placedBetSubject.error(err)
            );
    }
    private deleteBetRequest(betId: number, clientId: number) {
        const deleteBetRequest = fromBet.mapDeleteBetRequest(betId, clientId);
        this.httpClient.post(this.getBaseUrl(deleteBetRequest), deleteBetRequest.body, { params: deleteBetRequest.queryParameter })
            .pipe(take(1), catchError(err => throwError(err)))
            .subscribe(
                (response: any) => this._deleteBetSubject.next(response),
                err => console.log('deleteBetRequest', err)
            );
    }
    // LastShowTimeMarket(CentralizationID: string) {
    //     const lastShowTimeMarketRequest = fromBet.mapLastShowTimeMarkeRequest(CentralizationID);
    //     return this.httpClient.post(this.getBaseUrl(lastShowTimeMarketRequest), lastShowTimeMarketRequest.body, { params: lastShowTimeMarketRequest.queryParameter })
    //         .pipe(catchError(err => throwError(err)));
    // }
    getBaseUrl(item: any): string {
        let baseUrl: string;
        if (item.baseUrl) {
            baseUrl = `${item.baseUrl}${item.endPoint}`;
        } else {
            baseUrl = `${apiEndPointData.data.webProdMarketApiUrl}${item.endPoint}`;
        }
        return baseUrl;
    }
}


