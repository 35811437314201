import { Injectable, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ParkBetState } from '@clientApp-store/store.state';
import { ClearParkBetStore } from '@clientApp-store/store.meta-reducer';
import { SessionService } from './../session/session.service';
import { RemoveAllSelectedMarket } from '@clientApp-store/selected-market/actions/selected-market.actions';
import { Market } from '@clientApp-core/models/market/market.model';
import { take, catchError } from 'rxjs/operators';
import * as fromSelectedMarket from '@clientApp-store/selected-market/selectors/selected-market.selectors';
import { throwError } from 'rxjs';
import { NodeCentralService } from '../session/node-central.service';

@Injectable()
export class StoreService  {
    constructor(private store: Store<ParkBetState>, private router: Router,
       private sessionService: SessionService, private _compiler: Compiler, private nodeCentralService: NodeCentralService ) {}

    removeAllStoreData() {
        this.removeAllGroup();
        this.store.dispatch(RemoveAllSelectedMarket());
    }
    removeAllGroup() {
      const centralizationIds = this.getSelectedMarket().map(match => match.appCentralizationID).toString();
      if (centralizationIds && centralizationIds !== '' && centralizationIds.length > 0 ) {
        this.sessionService.removeAllCentralGroup(centralizationIds);
      }
    }

    clearStore() {
        // this.authService.LogOut$();
        this.clearLocalStorage();
       // this.stopHubConnections();
        this.nodeCentralService.StopSocketConnection();
        this.store.dispatch(new ClearParkBetStore());
        this.router.navigateByUrl('/login');
        this._compiler.clearCache();
      }

      stopHubConnections() {
        this.sessionService.stopHubConnections();
      }

      clearLocalStorage() {
        localStorage.removeItem('token');
        localStorage.removeItem('selected_matches');
        localStorage.removeItem('is_refreshed');
        localStorage.removeItem('showTandC');
        localStorage.removeItem('showVeronicaPopup');
        localStorage.removeItem('is_MultiPin');
        localStorage.removeItem('multiselected_matchIds');
        localStorage.removeItem('selectedSport');
        localStorage.removeItem('selected_betId');
      }
      private  getSelectedMarket(): Market[] {
        let selectedMarkets: Market[] ;
        this.store
        .pipe(select(fromSelectedMarket.getAllMarkets), take(1), catchError(err => throwError(err)))
        .subscribe(markets => selectedMarkets = markets, err => console.log('getSelectedMarket', err));
        return selectedMarkets;
      }
}
